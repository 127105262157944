import React from 'react';
import { Alert } from 'reactstrap';

export const Warning = ({ children }) => (
  <Alert className="py-1 mt-2" color="warning">
    {' '}
    {children}{' '}
  </Alert>
);
export const Success = ({ children }) => (
  <Alert className="py-1 mt-2" color="success">
    {' '}
    {children}{' '}
  </Alert>
);
export const Danger = ({ children }) => (
  <Alert className="py-1 mt-2" color="danger">
    {' '}
    {children}{' '}
  </Alert>
);
export const Info = ({ children }) => (
  <Alert className="py-1 mt-2" color="info">
    {' '}
    {children}{' '}
  </Alert>
);

export default { Warning, Success, Danger, Info };
