import React, { useEffect } from 'react';
import { useForm } from 'hooks';
import { Page, required, FormGroup, SubmitButton } from 'components/common';
import { NotificationManager } from 'react-notifications';
import { isInt } from 'validator';
import { List as SectionContentList } from './SectionContent';

export const Edit = ({
  match,
  client,
  location,
  url = 'sections',
  title = 'Section',
  history
}) => {
  const {
    getTitle,
    loaded,
    load,
    setValue,
    Form,
    Control,
    save,
    value
  } = useForm();

  const { id, module } = match.params;
  const defaultItem = {
    title: '',
    published: false,
    module
  };

  async function onMount() {
    let item = await load(client, url, id, defaultItem);
    if (!item.id) {
      const order = await client
        .get(`modules/${module}/${url}/next_order`)
        .get('data');
      item = {
        ...item,
        ...order
      };
    }
    setValue(item);
  }

  async function onSubmit(x) {
    const { id } = await save(client, url, x).get('data');
    history.replace(match.url.replace('/new', `/${id}`));
    NotificationManager.success('Saved successfully');
  }

  useEffect(() => void onMount(), [id]);

  if (!loaded) return null;

  return (
    <>
      <Page title={getTitle(id, title)}>
        <Form
          onSubmit={onSubmit}
          validators={{
            title: required,
            order: v => isInt(`${v}`, { min: 0 }),
            module: required
          }}
        >
          <FormGroup label="Title *" model=".title">
            <Control.text model=".title" className="form-control" />
          </FormGroup>
          <FormGroup label="Order *" model=".order">
            <Control.text model=".order" className="form-control" />
          </FormGroup>
          <FormGroup>
            <FormGroup check label="Published?" model=".published">
              <Control.checkbox
                model=".published"
                className="form-check-input"
              />
            </FormGroup>
          </FormGroup>
          <div className="flex justify-end">
            <SubmitButton>Save</SubmitButton>
          </div>
        </Form>
      </Page>
      {!!value.id && (
        <SectionContentList
          match={{ url: `${match.url}/content` }}
          section={value.id}
        />
      )}
    </>
  );
};

export default Edit;
