import React, { useEffect, useState } from 'react';
import { useForm } from 'hooks/useForm';
import {
  Page,
  required,
  FormGroup,
  SubmitButton,
  InputGroup,
  ConnectedEditor
} from 'components/common';
import { NotificationManager } from 'react-notifications';
import { isInt } from 'validator';
import Select from 'react-select';
import Dropzone from 'react-dropzone';
import { Player } from 'video-react';
import { Button } from 'reactstrap';

const typeOptions = ['Video', 'Reading', 'Practical'].map(i => ({
  label: i,
  value: i
}));

export const Edit = ({
  match,
  client,
  location,
  url = 'content',
  title = 'Content',
  history
}) => {
  const {
    getTitle,
    loaded,
    load,
    setValue,
    Form,
    Control,
    save,
    dispatch,
    actions,
    value
  } = useForm();
  const [file, setFile] = useState();
  const [showPreview, setShowPreview] = useState();

  const { id, section } = match.params;
  const defaultItem = {
    title: '',
    published: false,
    section
  };

  function resetState() {
    setFile(null);
    setShowPreview(false);
  }

  async function onMount() {
    resetState();

    let item = await load(client, url, id, defaultItem);
    if (!item.id) {
      const order = await client
        .get(`sections/${section}/${url}/next_order`)
        .get('data');
      item = {
        ...item,
        ...order
      };
    }
    setValue(item);
  }

  async function onSubmit(x) {
    const resp = await save(client, url, x).get('data');

    if (file && x.type === 'Video') {
      const data = new FormData();
      data.append('file', file);

      await save(client, `${url}/${resp.id}/video`, data);
      setFile(null);
    }
    resetState();
    dispatch(actions.merge('local', resp));
    history.replace(match.url.replace('/new', `/${resp.id}`));
    NotificationManager.success('Saved successfully');
  }

  function onDropAccepted([file]) {
    setFile(file);
    dispatch(actions.change('local.video.file', file.name));
  }

  useEffect(() => void onMount(), [id]);

  if (!loaded) return null;

  return (
    <Page title={getTitle(id, title)}>
      <Form
        onSubmit={onSubmit}
        validators={{
          title: required,
          order: v => isInt(`${v}`, { min: 0 }),
          estimated_time: v => isInt(`${v}`, { min: 0 }),
          section: required,
          type: required,
          'reading.content': v =>
            value.type === 'Reading' ? required(v) : true,
          'practical.content': v =>
            value.type === 'Practical' ? required(v) : true,
          'video.file': v => (value.type === 'Video' ? required(v) : true),
          'video.transcript': v => (value.type === 'Video' ? required(v) : true)
        }}
      >
        <FormGroup label="Title *" model=".title">
          <Control.text model=".title" className="form-control" />
        </FormGroup>
        <FormGroup label="Order *" model=".order">
          <Control.text model=".order" className="form-control" />
        </FormGroup>
        <FormGroup>
          <FormGroup check label="Published?" model=".published">
            <Control.checkbox model=".published" className="form-check-input" />
          </FormGroup>
        </FormGroup>
        <FormGroup label="Estimated Time *" model=".estimated_time">
          <InputGroup suffixText="minutes">
            <Control.text model=".estimated_time" className="form-control" />
          </InputGroup>
        </FormGroup>
        <FormGroup label="Type *" model=".type">
          <Control.text
            disabled={value.id}
            onChange={() => {
              dispatch(actions.omit('local', 'reading'));
              dispatch(actions.omit('local', 'video'));
              dispatch(actions.omit('local', 'practical'));
              resetState();
            }}
            model=".type"
            component={Select}
            searchable={false}
            options={typeOptions}
            simpleValue
          />
        </FormGroup>
        {value.type === 'Reading' && (
          <FormGroup label="Content" model=".reading.content">
            <ConnectedEditor model=".reading.content" />
          </FormGroup>
        )}
        {value.type === 'Practical' && (
          <FormGroup label="Content" model=".practical.content">
            <ConnectedEditor model=".practical.content" />
          </FormGroup>
        )}
        {false && value.type === 'Practical' && (
          <FormGroup
            label="Finish Year to Complete *"
            model=".practical.complete_year"
          >
            <Control.text
              className="form-control"
              model=".practical.complete_year"
            />
          </FormGroup>
        )}
        {value.type === 'Video' && (
          <>
            <FormGroup label="Video File *" model=".video.file">
              <Dropzone
                style={{}}
                className="border rounded p-2 text-sm"
                onDropAccepted={onDropAccepted}
                multiple={false}
              >
                {value.video && value.video.file
                  ? value.video.file
                  : 'Click here to select a video'}
              </Dropzone>
              {value.video && value.video.file && !file ? (
                <Button
                  color="link"
                  onClick={() => setShowPreview(!showPreview)}
                  className="p-1"
                >
                  {showPreview ? 'Hide ' : 'Show '} Preview
                </Button>
              ) : null}
              {showPreview && (
                <Player src={`/api/sections/${value.id}/video/serve`} />
              )}
            </FormGroup>
            <FormGroup label="Transcript" model=".video.transcript">
              <ConnectedEditor
                editorClassName="draft__editor"
                wrapperClassName="draft__wrapper"
                model=".video.transcript"
              />
            </FormGroup>
          </>
        )}
        <div className="flex justify-end">
          <SubmitButton>Save</SubmitButton>
        </div>
      </Form>
    </Page>
  );
};

export default Edit;
