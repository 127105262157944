import {
  ViewingRecords,
  Pagination,
  TableContainer,
  BulkDeleteButton,
  TableSearch,
  AddNewButton,
  EditDeleteButtons,
  TableDataFetch
} from 'components/common';
import { reduce, keyBy, keys, isFunction } from 'lodash';
import shortid from 'shortid';
import React, { useState, useRef, useMemo } from 'react';

const createComponents = ref => () => {
  return {
    Pagination: props => {
      const { limit, offset, setOffset, data } = ref.current;

      return (
        <Pagination
          size="sm"
          limit={limit}
          offset={offset}
          onOffsetChanged={setOffset}
          count={data.count}
          {...props}
        />
      );
    },

    AddNewButton: props => {
      const { match, resource } = ref.current;

      return <AddNewButton match={match} resource={resource} {...props} />;
    },

    ViewingRecords: props => {
      const { offset, limit, data } = ref.current;

      return (
        <ViewingRecords
          offset={offset}
          count={data.count}
          limit={limit}
          {...props}
        />
      );
    },

    TableContainer: props => {
      const {
        data,
        ordering,
        setOrdering,
        selected,
        setSelected
      } = ref.current;

      return (
        <TableContainer
          items={data.results}
          ordering={ordering}
          onOrderingChanged={setOrdering}
          selected={selected}
          onSelectedChanged={setSelected}
          {...props}
        />
      );
    },

    Loader: () => {
      const { data } = ref.current;
      return data.loading ? (
        <tr>
          <td colSpan="100" className="text-center">
            <span className="fa fa-spinner fa-spin mt-2" /> Loading...
          </td>
        </tr>
      ) : null;
    },

    TableSearch: props => {
      const { setSearch, search } = ref.current;

      return <TableSearch onChange={setSearch} value={search} {...props} />;
    },

    EditDeleteButtons: props => {
      const { match, onDeleteItem } = ref.current;
      return (
        <EditDeleteButtons
          url={match ? match.url : null}
          onDelete={onDeleteItem}
          {...props}
        />
      );
    },

    TableDataFetch: props => {
      const {
        url,
        ordering,
        offset,
        limit,
        filters,
        search,
        client,
        setData,
        refreshToken
      } = ref.current;
      const localProps = {
        url,
        ordering,
        offset,
        limit,
        filters,
        search,
        client,
        refreshToken,
        onDataFetched: setData
      };

      return <TableDataFetch {...localProps} {...props} />;
    },

    BulkDeleteButton: props => {
      const {
        toggleDeleteModal,
        selected,
        onDeleted,
        isDeleteModalOpen,
        url
      } = ref.current;

      const localProps = {
        toggleModal: toggleDeleteModal,
        selected,
        url,
        onDeleted,
        isModalOpen: isDeleteModalOpen
      };

      return <BulkDeleteButton {...localProps} {...props} />;
    }
  };
};

export const useTable = ({
  idKey = 'id',
  url,
  match,
  resource,
  client,
  filters: initialFilters = {},
  limit: initialLimit = 50
} = {}) => {
  const [data, _setData] = useState({ count: 0, results: [] });
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(initialLimit);
  const [filters, setFilters] = useState(initialFilters);
  const [search, setSearch] = useState(null);
  const [selected, _setSelected] = useState({});
  const [ordering, setOrdering] = useState('');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [refreshToken, setRefreshToken] = useState(null);
  const [numSelected, setNumSelected] = useState(0);

  const state = {
    data,
    offset,
    limit,
    filters,
    numSelected,
    search,
    selected,
    ordering,
    isDeleteModalOpen,
    refreshToken
  };

  const handlers = {
    setOffset,
    setLimit,
    setFilters,
    setOrdering,
    setSearch,
    setData: data => {
      const results = keyBy(data.results, idKey);

      const newSelected = reduce(
        selected,
        (acc, value, key) => {
          if (results[key]) acc[key] = true;
          return acc;
        },
        {}
      );

      _setData(data);
      _setSelected(newSelected);
    },
    mergeFilters: x => {
      setFilters({
        ...filters,
        ...x
      });
    },
    setSelected: selected => {
      _setSelected(selected);
      setNumSelected(keys(selected).length);
    },
    updateResults: arg => {
      let results = isFunction(arg) ? arg(data.results) : arg;

      _setData({
        ...data,
        results
      });
    },
    toggleDeleteModal: () => setIsDeleteModalOpen(!isDeleteModalOpen),
    onDeleted: () => setRefreshToken(shortid()),
    refreshData: () => setRefreshToken(shortid()),
    onDeleteItem: id => {
      setIsDeleteModalOpen(true);
      _setSelected({ [id]: true });
    }
  };

  const result = {
    ...state,
    ...handlers,
    url,
    match,
    client,
    resource
  };

  const resultRef = useRef();
  resultRef.current = result;

  const components = useMemo(createComponents(resultRef), []);

  return {
    ...result,
    ...components
  };
};

export default useTable;
