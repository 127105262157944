import React, { useEffect } from 'react';
import { useForm } from 'hooks';
import { Page, required, FormGroup, SubmitButton } from 'components/common';
import { NotificationManager } from 'react-notifications';
import { isInt } from 'validator';
import { List as SectionList } from './Sections';

export const Edit = ({
  match,
  client,
  location,
  url = 'modules',
  title = 'Module',
  history
}) => {
  const {
    getTitle,
    loaded,
    load,
    setValue,
    Form,
    Control,
    save,
    value
  } = useForm();

  const { id, course } = match.params;
  const defaultItem = {
    title: '',
    published: false,
    course
  };

  async function onMount() {
    let item = await load(client, url, id, defaultItem);
    if (!item.id) {
      const order = await client
        .get(`courses/${course}/${url}/next_order`)
        .get('data');
      item = {
        ...item,
        ...order
      };
    }
    setValue(item);
  }

  async function onSubmit(x) {
    const { id } = await save(client, url, x).get('data');
    history.replace(match.url.replace('/new', `/${id}`));
    NotificationManager.success('Saved successfully');
  }

  useEffect(() => void onMount(), [id]);

  if (!loaded) return null;

  return (
    <>
      <Page title={getTitle(id, title)}>
        <Form
          onSubmit={onSubmit}
          validators={{
            title: required,
            order: v => isInt(`${v}`, { min: 0 }),
            course: required
          }}
        >
          <FormGroup label="Title *" model=".title">
            <Control.text model=".title" className="form-control" />
          </FormGroup>
          <FormGroup label="Order *" model=".order">
            <Control.text model=".order" className="form-control" />
          </FormGroup>
          <FormGroup>
            <FormGroup check label="Published?" model=".published">
              <Control.checkbox
                model=".published"
                className="form-check-input"
              />
            </FormGroup>
          </FormGroup>
          <FormGroup label="Overview" model=".overview">
            <Control.textarea
              rows="5"
              model=".overview"
              className="form-control"
            />
          </FormGroup>
          <div className="flex justify-end">
            <SubmitButton>Save</SubmitButton>
          </div>
        </Form>
      </Page>
      {!!value.id && (
        <SectionList
          match={{ url: `${match.url}/sections` }}
          module={value.id}
        />
      )}
    </>
  );
};

export default Edit;
