import React, { createContext, useEffect, useState, useMemo } from 'react';
import { subscribe, loggedIn, clearToken } from 'services/Auth';

const UserContext = createContext();

export const UserContextProvider = ({ children }) => {
  const [, forceUpdate] = useState(false);

  useEffect(() => {
    let unsubscribe = subscribe(() => {
      forceUpdate(v => !v);
    });

    return () => unsubscribe();
  }, []);

  function logout() {
    clearToken();
  }

  const token = loggedIn();

  const value = useMemo(
    () => ({
      user: token || null,
      isAdmin: !!(token && token.is_superuser),
      isUser: !!(token && !token.is_superuser),
      logout
    }),
    [token ? token.id : null]
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export default UserContext;
