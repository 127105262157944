import React, { createContext, useReducer, useMemo } from 'react';
import { findIndex, reduce, forEach, sumBy, cloneDeep, find } from 'lodash';
import produce, { setAutoFreeze } from 'immer';
import numeral from 'numeral';
import shortid from 'shortid';

setAutoFreeze(false);

const calculateTransactionBalances = (quadrant, account) => {
  account.total = reduce(
    account.transactions,
    (total, i) => {
      if (quadrant === 'revenue' || quadrant === 'equity') {
        if (i.credit) total = total.add(i.credit);
        if (i.debit) total = total.subtract(i.debit);
      } else if (quadrant === 'expenses' || quadrant === 'assets') {
        if (i.credit) total = total.subtract(i.credit);
        if (i.debit) total = total.add(i.debit);
      }

      i.balance = total.format('0');
      return total;
    },
    numeral(0)
  );
  account.total = account.total.format('0');
};

const calculateTotals = year => {
  const quadrants = ['expenses', 'revenue', 'assets', 'equity'];
  const totals = year.totals;

  forEach(quadrants, quadrant => {
    totals[quadrant] = numeral(
      sumBy(year.accounts[quadrant], i => parseFloat(i.total))
    ).format('0');
  });

  totals.in = numeral(totals.expenses)
    .add(totals.assets)
    .format('0');
  totals.out = numeral(totals.revenue)
    .add(totals.equity)
    .format('0');
};

const cloneAccounts = v =>
  v.map(({ total, name }) => ({ total, name, transactions: [] }));

const setBalanceSheet = year => {
  const { assets: asset_accounts, equity: equity_accounts } = year.accounts;
  const { assets: assets_total, equity: equity_total } = year.totals;

  year.balance_sheet = {
    asset_accounts,
    assets_total,
    equity_accounts,
    equity_total
  };
};

const setProfitLoss = year => {
  const {
    expenses: expense_accounts,
    revenue: revenue_accounts
  } = year.accounts;
  const { expenses: expenses_total, revenue: revenue_total } = year.totals;
  const total = numeral(revenue_total).subtract(expenses_total);

  year.profit_loss = {
    revenue_accounts: cloneAccounts(revenue_accounts),
    expense_accounts: cloneAccounts(expense_accounts),
    total: total.format('0')
  };
};

const setProfitLossAndBalanceSheet = year => {
  setProfitLoss(year);
  setBalanceSheet(year);
};

export const reducer = (state, { type, payload }) => {
  state.redo = state.redo || [];
  state.undo = state.undo || [];

  if (type === 'undo') {
    const newState = state.undo.pop();
    if (newState) newState.redo.push(state);
    return newState || state;
  } else if (type === 'redo') {
    const newState = state.redo.pop();
    if (newState) newState.undo.push(state);
    return newState || state;
  }

  const newState = produce(state, i => {
    const getYear = () => i.years[i.selectedYear];
    const getAccounts = ({ quadrant }) =>
      i.years[i.selectedYear].accounts[quadrant];
    const getAccount = ({ accountIndex, ...payload }) =>
      getAccounts(payload)[accountIndex];

    ({
      add_account: ({ account, ...payload }) => {
        getAccounts(payload).push(account);
        setProfitLossAndBalanceSheet(getYear());
      },
      remove_account: ({ index, ...payload }) => {
        getAccounts(payload).splice(index, 1);
        calculateTotals(getYear(payload));
        setProfitLossAndBalanceSheet(getYear());
      },
      add_transaction: ({ transaction, ...payload }) => {
        const account = getAccount(payload);
        const transactions = account.transactions;
        transactions.push(transaction);
        calculateTransactionBalances(payload.quadrant, account);
        calculateTotals(getYear(payload));
        setProfitLossAndBalanceSheet(getYear());
      },
      set_status: ({ status }) => {
        const year = getYear();
        year.status = status;

        if (status === 'EDITING') {
          setBalanceSheet(year);
        } else if (status === 'REVIEW') {
          setProfitLossAndBalanceSheet(getYear());
          const simulateYear = cloneDeep(year);
          const { revenue, expenses, equity } = simulateYear.accounts;
          let accumulatedProfitAccount = find(equity, {
            name: 'Accumulated Profit'
          });
          if (!accumulatedProfitAccount) {
            accumulatedProfitAccount = {
              name: 'Accumulated Profit',
              transactions: [],
              total: '0'
            };
            equity.push(accumulatedProfitAccount);
          }

          revenue.map(i => {
            accumulatedProfitAccount.transactions.push({
              credit: i.total,
              ac_name: '',
              ref: '',
              debit: '0'
            });
            return i;
          });

          expenses.map(i => {
            accumulatedProfitAccount.transactions.push({
              debit: i.total,
              credit: '0',
              ac_name: '',
              ref: ''
            });
            return i;
          });

          equity.map(i => calculateTransactionBalances('equity', i));

          calculateTotals(simulateYear);
          setBalanceSheet(simulateYear);
          year.balance_sheet = simulateYear.balance_sheet;
        }
      },
      remove_transaction: ({ index, ...payload }) => {
        const account = getAccount(payload);
        const transactions = account.transactions;
        transactions.splice(index, 1);
        calculateTransactionBalances(payload.quadrant, account);
        calculateTotals(getYear(payload));
        setProfitLossAndBalanceSheet(getYear());
      },
      finalize_year: payload => {
        const year = getYear(payload);
        const { revenue, expenses, equity, assets } = year.accounts;

        let accumulatedProfitAccount = find(equity, {
          name: 'Accumulated Profit'
        });
        if (!accumulatedProfitAccount) {
          accumulatedProfitAccount = {
            name: 'Accumulated Profit',
            transactions: [],
            total: '0'
          };
          equity.push(accumulatedProfitAccount);
        }

        revenue.map(i => {
          i.transactions.push({
            ac_name: `Year ${year.year} end to clear balance`,
            ref: '',
            key: shortid(),
            debit: i.total,
            credit: '0'
          });
          accumulatedProfitAccount.transactions.push({
            ac_name: `${i.name} from Year ${year.year}`,
            ref: '',
            key: shortid(),
            credit: i.total,
            debit: '0'
          });
          calculateTransactionBalances('revenue', i);
          return i;
        });

        expenses.map(i => {
          i.transactions.push({
            ac_name: `Year ${year.year} end to clear balance`,
            ref: '',
            key: shortid(),
            credit: i.total,
            debit: '0'
          });
          accumulatedProfitAccount.transactions.push({
            ac_name: `${i.name} from Year ${year.year}`,
            ref: '',
            key: shortid(),
            debit: i.total,
            credit: '0'
          });
          calculateTransactionBalances('expenses', i);
          return i;
        });

        [...revenue, ...expenses, ...equity, ...assets].map(i =>
          i.transactions.map(j => {
            j.locked = true;
            return j;
          })
        );

        equity.map(i => calculateTransactionBalances('equity', i));
        year.status = 'FINAL';
        calculateTotals(year);
        setBalanceSheet(year);
        const newYear = cloneDeep(year);
        setProfitLoss(newYear);

        newYear.year = year.year + 1;
        newYear.status = 'EDITING';

        i.years.push(newYear);
      },
      set_selected_year: index => {
        i.selectedYear = index;
      }
    }[type](payload));
  });

  if (type === 'finalize_year') {
    newState.undo = [];
    newState.redo = [];
  } else {
    if (newState !== state) {
      newState.undo.push(state);
      newState.redo = [];
    }
  }

  return newState;
};
export const PracticeToolContext = createContext();

export const PracticeToolProvider = ({ initialState, children }) => {
  let selectedYear = findIndex(initialState.years, i => i.status === 'EDITING');

  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    selectedYear: selectedYear !== -1 ? selectedYear : 0
  });

  const actions = useMemo(
    () => ({
      addTransaction: (quadrant, accountIndex, transaction) =>
        dispatch({
          type: 'add_transaction',
          payload: {
            quadrant,
            accountIndex,
            transaction
          }
        }),
      undo: () =>
        dispatch({
          type: 'undo'
        }),
      redo: () =>
        dispatch({
          type: 'redo'
        }),
      finalizeYear: () =>
        dispatch({
          type: 'finalize_year'
        }),
      setStatus: status =>
        dispatch({
          type: 'set_status',
          payload: {
            status
          }
        }),
      removeTransaction: (quadrant, accountIndex, index) =>
        dispatch({
          type: 'remove_transaction',
          payload: {
            quadrant,
            accountIndex,
            index
          }
        }),
      setSelectedYear: payload =>
        dispatch({ type: 'set_selected_year', payload }),
      addAccount: (quadrant, account) =>
        dispatch({
          type: 'add_account',
          payload: {
            quadrant,
            account
          }
        }),
      removeAccount: (quadrant, index) =>
        dispatch({
          type: 'remove_account',
          payload: {
            quadrant,
            index
          }
        })
    }),
    []
  );
  const value = useMemo(() => ({ state, dispatch, actions }), [
    state,
    dispatch,
    actions
  ]);

  return (
    <PracticeToolContext.Provider value={value}>
      {children}
    </PracticeToolContext.Provider>
  );
};
