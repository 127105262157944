import React from 'react';
import { useForm } from 'hooks';
import { FormGroup, required, SubmitButton, Page } from 'components/common';
import { Auth } from 'services';
import { isEmail } from 'validator';
import { get } from 'lodash';
import { NotificationManager } from 'react-notifications';
import { Link } from 'react-router-dom';

export const Login = ({ history }) => {
  const { Form, Control, dispatch, actions } = useForm({
    initialState: {
      email: '',
      password: ''
    }
  });

  async function onSubmit({ email, password }) {
    try {
      await Auth.login({ email, password });
      history.push('/');
    } catch (ex) {
      if (get(ex, 'response.status') === 400) {
        NotificationManager.error(
          'Unable to sign in with the provided credentials'
        );
      } else {
        throw ex;
      }
    } finally {
      dispatch(actions.setPending('local', false));
    }
  }

  return (
    <Page title="Sign In" className="max-w-sm mx-auto">
      <Form
        validators={{
          email: isEmail,
          password: required
        }}
        onSubmit={onSubmit}
      >
        <FormGroup label="Email" model=".email">
          <Control.text className="form-control" model=".email" />
        </FormGroup>
        <FormGroup label="Password" model=".password">
          <Control.input
            type="password"
            className="form-control"
            model=".password"
          />
        </FormGroup>
        <div className="flex items-center">
          <Link to="/password_reset_request" className="link mr-auto">
            Forgot Password?
          </Link>
          <SubmitButton>
            <span className="fa fa-sign-in mr-2" />
            Sign In
          </SubmitButton>
        </div>
        <hr />
        <div>
          Don't have an account?{' '}
          <Link className="link" to="/register">
            Register Here
          </Link>
        </div>
      </Form>
    </Page>
  );
};

export default Login;
