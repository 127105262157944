import React, { useMemo } from 'react';
import classNames from 'classnames';

export const PageTitle = ({
  component: Component = 'h4',
  className,
  children,
  ...props
}) => (
  <Component className={classNames('font-normal mb-4', className)} {...props}>
    {children}
  </Component>
);

export const PageCard = ({
  component: Component = 'div',
  className,
  children,
  ...props
}) => (
  <Component
    className={classNames(
      'border border-solid mb-5 border-grey rounded bg-snow',
      className
    )}
    {...props}
  >
    {children}
  </Component>
);

export const usePageHelpers = () => {
  return useMemo(() => ({
    PageTitle,
    PageCard
  }));
};

export default usePageHelpers;
