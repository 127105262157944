import React, { useEffect, useState } from 'react';
import { useForm } from 'hooks';
import { Page, SubmitButton, FormGroup } from 'components/common';
import { NotificationManager } from 'react-notifications';
import Select from 'react-select';
import produce from 'immer';
import numeral from 'numeral';
import { find } from 'lodash';

export const Settings = ({ client }) => {
  const { Form, Control, actions, dispatch, setValue, loaded } = useForm();
  const [stripePlans, setStripePlans] = useState();

  useEffect(
    () =>
      void (async () => {
        const item = await client.get('global_settings').get('data');
        setValue(item);
      })(),
    []
  );

  useEffect(() => {
    (async () => {
      const stripePlans = await client
        .get('stripe/plans')
        .get('data')
        .then(
          produce(draft => {
            draft.map(i => {
              const { amount, id, currency, interval, nickname } = i;
              i.label = `${nickname} @ ${numeral(amount)
                .divide(100)
                .format(
                  '0.00'
                )}${currency} per user per ${interval} - ${i.trial_period_days ||
                'No'} days trial`;
              i.value = id;
              return i;
            });
          })
        );
      setStripePlans(stripePlans);

      await client.get('stripe/subscriptions').get('data');
    })();
  }, []);

  async function onSubmit(x) {
    try {
      await client.post('global_settings', x);
      NotificationManager.success('Saved successfully');
    } finally {
      dispatch(actions.setPending('local', false));
    }
  }

  if (!loaded) return null;

  return (
    <Page title="Settings">
      <Form
        validators={{
          stripe_plan: id => {
            return !!find(stripePlans, { id });
          }
        }}
        onSubmit={onSubmit}
      >
        <FormGroup label="Stripe Plan *" model=".stripe_plan">
          <Control.text
            component={Select}
            model=".stripe_plan"
            valueKey="id"
            options={stripePlans}
            simpleValue
          />
        </FormGroup>
        <div className="flex justify-end">
          <SubmitButton>Save</SubmitButton>
        </div>
      </Form>
    </Page>
  );
};

export default Settings;
