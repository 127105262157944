import validator from 'validator';

export const isCurrency = v =>
  validator.isDecimal(v, { decimal_digits: '0,2' }) &&
  validator.isFloat(v, { min: 0 });

export const isInteger = (v, options) => validator.isInt(`${v}`, options);

export const isEmail = validator.isEmail;

export const required = v => {
  return !(v === '' || v === null || v === undefined);
};
