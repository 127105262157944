import React, { useCallback } from 'react';

import { get } from 'lodash';
import {
  Page,
  FormGroup,
  SubmitButton,
  Warning,
  Success
} from 'components/common';
import { isEmail } from 'validator';
import { useForm } from 'hooks';
import { Errors } from 'react-redux-form';

const PasswordResetRequest = ({ client }) => {
  const { Form, Control, dispatch, actions } = useForm({
    initialState: { email: '' }
  });

  const Container = useCallback(
    props => <Page title="Reset Your Password" {...props} />,
    []
  );

  async function onSubmit(x) {
    try {
      dispatch(actions.resetErrors('info'));
      await client.post('/password_reset_request', x);
      dispatch(
        actions.setErrors(
          'info.success',
          'An email has been sent to the specified email address.'
        )
      );
    } catch (ex) {
      if (get(ex, 'response.data.code') === 'EmailFailed')
        dispatch(
          actions.setErrors(
            'info.emailFailed',
            'The email failed to send. Please try again later.'
          )
        );
      else throw ex;
    } finally {
      dispatch(actions.setPending('local', false));
    }
  }

  return (
    <Container title="Forgotten your password?">
      <p>
        Enter your Email below and a link to reset your password will be sent to
        you.
      </p>
      <Form validators={{ email: isEmail }} onSubmit={onSubmit}>
        <FormGroup label="" model=".email">
          <Control.text
            placeholder="Email"
            model=".email"
            className="form-control"
          />
          <Errors model="info.emailFailed" component={Warning} />
          <Errors model="info.success" component={Success} />
        </FormGroup>

        <div className="flex justify-end">
          <SubmitButton>Send Reset Link</SubmitButton>
        </div>
      </Form>
    </Container>
  );
};

export default PasswordResetRequest;
