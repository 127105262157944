import React, { useEffect, useState } from 'react';
import { Player } from 'video-react';
import { PendingButton } from 'components/common';
import { Button } from 'reactstrap';
import draftToHtml from 'draftjs-to-html';
import produce from 'immer';
import { generatePath } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { usePageHelpers } from 'hooks';

export const View = ({ client, match, history }) => {
  const [data, setData] = useState();
  const [completePending, setCompletePending] = useState();
  const [showTranscript, setShowTranscript] = useState(false);
  const { PageTitle, PageCard } = usePageHelpers();
  const { id } = match.params;
  const url = `user_content/content/${id}`;

  function getContentPath(id) {
    return generatePath(match.path, {
      ...match.params,
      id
    });
  }

  useEffect(
    () => {
      (async () => {
        setData(null);
        const data = await client
          .get(`user_content/content/${id}`)
          .get('data')
          .then(
            produce(draft => {
              draft.video__transcript = draft.video__transcript
                ? draftToHtml(draft.video__transcript)
                : null;
              draft.practical__content = draft.practical__content
                ? draftToHtml(draft.practical__content)
                : null;
              draft.video__src = draft.video__file
                ? `/api/content/${draft.id}/video/serve`
                : null;
              draft.reading__content = draft.reading__content
                ? draftToHtml(draft.reading__content)
                : null;
              draft.previous_content_path = draft.previous_content
                ? getContentPath(draft.previous_content)
                : null;
              draft.next_content_path = draft.next_content
                ? getContentPath(draft.next_content)
                : null;
            })
          );

        setData(data);
      })();
    },
    [id]
  );

  async function onComplete() {
    setCompletePending(true);
    await client.post(`${url}/completed`);
    setCompletePending(false);
    if (data.next_content_path) {
      history.push(data.next_content_path);
    } else {
      history.push(history.resolvePath('../..'));
    }
  }

  useEffect(
    () => {
      if (!data) return;

      (async () => {
        await client.post(`${url}/started`);
      })();
    },
    [data]
  );

  if (!data) return null;

  return (
    <div>
      <PageTitle>
        {data.section__module__name}: {data.title}
      </PageTitle>
      <PageCard className="p-3">
        {!!data.video__src && (
          <div>
            <Player src={data.video__src} />
            {!!data.video__transcript && (
              <div>
                <div className="flex justify-end">
                  <Button
                    color="link"
                    onClick={() => setShowTranscript(!showTranscript)}
                  >
                    {showTranscript ? 'HIDE' : 'VIEW'} TRANSCRIPT
                  </Button>
                </div>
                {!!showTranscript && (
                  <div
                    dangerouslySetInnerHTML={{ __html: data.video__transcript }}
                  />
                )}
              </div>
            )}
          </div>
        )}
        {!!data.reading__content && (
          <div>
            <div
              dangerouslySetInnerHTML={{
                __html: data.reading__content
              }}
            />
          </div>
        )}
        {!!data.practical__content && (
          <div>
            <div
              dangerouslySetInnerHTML={{
                __html: data.practical__content
              }}
            />
            <div className="my-3">
              Go to the <Link to="/accq_tool">ACCQ Tool</Link> to perform this
              exercise
            </div>
          </div>
        )}
        <div className="flex">
          {data.previous_content_path && (
            <Link className="btn btn-primary" to={data.previous_content_path}>
              View Previous Lesson
            </Link>
          )}
          <div className="m-auto" />
          <PendingButton onClick={onComplete} pending={completePending}>
            <span className="fa fa-fw fa-check mr-1" />
            {data.next_content_path
              ? 'Mark Complete & Start Next Lesson'
              : 'Complete Section'}
          </PendingButton>
        </div>
      </PageCard>
    </div>
  );
};

export default View;
