import React, {
  createContext,
  useEffect,
  useState,
  useMemo,
  useContext,
  useCallback
} from 'react';
import { UserContext } from 'contexts';
import { ApiClient } from 'services';

const MembershipContext = createContext();

export const MembershipProvider = ({ children }) => {
  const { user, isAdmin } = useContext(UserContext);

  const [membership, setMembership] = useState(null);
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);

  const reload = useCallback(
    async ({ silent } = {}) => {
      if (!silent) {
        setLoading(true);
      }

      if (!user || isAdmin) {
        setMembership(null);
        setProfile(null);
      } else {
        const client = ApiClient();

        const [membership, profile] = await Promise.all([
          client.get('membership').get('data'),
          client.get('user_profile').get('data')
        ]);
        setProfile(profile);
        setMembership(membership.id ? membership : null);
      }

      if (!silent) {
        setLoading(false);
      }
    },
    [user ? user.id : null, isAdmin]
  );

  const value = useMemo(
    () => ({
      loading,
      membership,
      profile,
      reload,
    }),
    [loading, membership, reload]
  );

  useEffect(() => void reload(), [user ? user.id : null]);

  return (
    <MembershipContext.Provider value={value}>
      {children}
    </MembershipContext.Provider>
  );
};

export default MembershipContext;
