import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toWords } from 'number-to-words';
import classNames from 'classnames';
import { toTime } from 'services/formatters';
import produce from 'immer';
import { generatePath } from 'react-router-dom';
import { startCase } from 'lodash';
import { usePageHelpers } from 'hooks';

export const View = ({ client, match }) => {
  const [data, setData] = useState();
  const { id, course } = match.params;
  const { PageTitle, PageCard } = usePageHelpers();

  function getModulePath(id) {
    return generatePath(match.path, {
      ...match.params,
      id
    });
  }

  useEffect(
    () => {
      (async () => {
        setData(null);
        const data = await client
          .get(`user_content/courses/${course}/modules/${id}`)
          .get('data')
          .then(
            produce(draft => {
              if (draft.previous_module) {
                draft.previous_module_url = getModulePath(
                  draft.previous_module
                );
              }
              if (draft.next_module && draft.completed) {
                draft.next_module_url = getModulePath(draft.next_module);
              }
            })
          );
        setData(data);
      })();
    },
    [id]
  );

  if (!data) return null;

  return (
    <div>
      <div className="flex justify-between mb-4 items-start">
        <PageTitle>{data.title}</PageTitle>
        {!!data.next_module_url && (
          <Link className="btn btn-darkslategray" to={data.next_module_url}>
            Next Module <span className="fa fa-chevron-right" />
          </Link>
        )}
      </div>

      <PageCard className="pt-3">
        {data.sections.map((i, idx) => (
          <div key={i.id}>
            <div className="px-6">{idx > 0 && <hr />}</div>

            <div className="py-6 px-6">
              <div className="font-bold mb-3">
                Section {startCase(toWords(idx + 1))} - {i.title}
              </div>
              {i.content.map(j => {
                return (
                  <div className="flex ml-3 mb-2 items-center">
                    <div
                      className={classNames('fa fa-fw mr-4', {
                        'fa-play': !j.completed && j.type === 'Video',
                        'fa-file': !j.completed && j.type === 'Reading',
                        'fa-circle-o-notch':
                          !j.completed && j.type === 'Practical',
                        'fa-check text-success': j.completed
                      })}
                    />
                    <div className="w-64 mr-3">
                      <Link to={`${match.url}/content/${j.id}`}>{j.title}</Link>
                    </div>
                    <div>{toTime(j.estimated_time)}</div>
                  </div>
                );
              })}
            </div>
          </div>
        ))}
      </PageCard>
    </div>
  );
};

export default View;
