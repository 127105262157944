import React from 'react';
import { useForm, usePageHelpers } from 'hooks';
import { SubmitButton, Success, Warning } from 'components/common';
import {
  CardElement,
  Elements,
  injectStripe,
  StripeProvider
} from 'react-stripe-elements';
import { FormGroup } from 'reactstrap';
import { get } from 'lodash';
import { Errors } from 'react-redux-form';
import { NotificationManager } from 'react-notifications';

const Payment = injectStripe(({ stripe, ...props }) => {
  async function onChange(card) {
    let result = null;
    if (card.complete && !card.error) {
      const response = await stripe.createSource({ type: 'card' });
      result = get(response, 'source.id');
    }
    props.onChange(result);
  }
  return <CardElement onChange={onChange} />;
});

export const UpdatePaymentMethod = ({ client, history }) => {
  const { PageTitle, PageCard } = usePageHelpers();
  const { Form, Control, dispatch, actions } = useForm();

  async function onSubmit(x) {
    if (!x.payment_source)
      dispatch(
        actions.setErrors(
          'info.warning',
          'Please enter your card details fully'
        )
      );
    else
      try {
        dispatch(actions.resetErrors('info'));
        await client.post('membership/update_payment_details', x);
        history.pushUp();
        NotificationManager.success(
          'Your payment details have been updated and will be used in the next billing cycle'
        );
      } catch (ex) {
        dispatch(actions.setErrors('info.warning', 'An error has occurred'));
      } finally {
        dispatch(actions.setPending('local', false));
      }
  }

  return (
    <div>
      <PageTitle>Update Payment Method</PageTitle>
      <PageCard className="p-3">
        <Form onSubmit={onSubmit}>
          <FormGroup label="Payment Details">
            <StripeProvider
              apiKey={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
            >
              <Elements>
                <Control.custom
                  mapProps={({ onChange }) => ({ onChange })}
                  model=".payment_source"
                  component={Payment}
                />
              </Elements>
            </StripeProvider>
          </FormGroup>
          <Errors model="info.success" component={Success} />
          <Errors model="info.warning" component={Warning} />
          <div className="flex justify-end">
            <SubmitButton model="local" disableInvalid>
              Update
            </SubmitButton>
          </div>
        </Form>
      </PageCard>
    </div>
  );
};

export default UpdatePaymentMethod;
