import React from 'react';
import { Control } from 'react-redux-form';
import { PendingButton } from 'components/common';
import { Trans } from 'services/i18n';

export const SubmitButton = ({
  model = 'local',
  disableInvalid = false,
  children,
  disabled,
  ...props
}) => {
  return (
    <Control.custom
      model={model}
      component={PendingButton}
      mapProps={{
        disabled: props => {
          if (!(disabled === undefined)) return disabled;
          if (disableInvalid) return undefined;
          return !props.fieldValue.valid;
        },
        pending: props => props.fieldValue.pending
      }}
      color="primary"
      {...props}
    >
      <Trans>{children}</Trans>
    </Control.custom>
  );
};

export default SubmitButton;
