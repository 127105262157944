import createBrowserHistory from 'history/createBrowserHistory';
import { stripTrailingSlash } from 'history/PathUtils';
import resolvePathname from 'resolve-pathname';
const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL || '/'
});

function resolvePath(path) {
  const { pathname } = this.location;
  return stripTrailingSlash(resolvePathname(path, `${pathname}/`));
}

function pushUp({ search, state } = {}) {
  return this.push({
    pathname: this.resolvePath('..'),
    search,
    state
  });
}

function replaceUp({ search, state, pathname = '..' } = {}) {
  return this.replace({
    pathname: this.resolvePath(pathname),
    search,
    state
  });
}

history.pushUp = pushUp.bind(history);
history.replaceUp = replaceUp.bind(history);
history.resolvePath = resolvePath.bind(history);

export default history;
