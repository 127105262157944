/* eslint no-shadow: 0 */
/* eslint prefer-const: 0 */
import React, { Children, cloneElement } from 'react';
import { compose, withState, withHandlers, defaultProps } from 'recompose';
import { chain } from 'lodash';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const ASC_ORDERING = 1;
const DESC_ORDERING = 2;

export class NoKeyDefinedError {
  constructor(message) {
    this.message = message;
  }
}

const TableContainer = ({
  onSelectAllChanged,
  selectAll,
  selected,
  onSelectChanged,
  onOrderingChanged,
  ordering,
  className: tmpClassName,
  ...props
}) => {
  const table = Children.only(props.children);

  const children = Children.map(table.props.children, i => {
    let { children, ...props } = i.props;

    if (i.type === 'thead') {
      children = Children.map(children, tr => {
        let { children, ...props } = tr.props;

        children = Children.map(children, th => {
          let { select, sortBy, children, ...props } = th.props;

          if (typeof select !== 'undefined') {
            children = (
              <input
                type="checkbox"
                onChange={onSelectAllChanged}
                value={selectAll}
              />
            );
          } else if (typeof sortBy !== 'undefined') {
            return (
              <th.type {...props}>
                <a
                  href="##"
                  onClick={e => e.preventDefault() & onOrderingChanged(sortBy)}
                >
                  {children}{' '}
                  {ordering && ordering.column === sortBy ? (
                    <span
                      className={classNames('fa', {
                        'fa-sort-desc': ordering.direction === ASC_ORDERING,
                        'fa-sort-asc': ordering.direction === DESC_ORDERING
                      })}
                    />
                  ) : (
                    <span className="fa fa-sort" />
                  )}
                </a>
              </th.type>
            );
          }
          return <th.type {...props}>{children}</th.type>;
        });
        return <tr.type {...props}>{children}</tr.type>;
      });
    } else if (i.type === 'tbody') {
      children = Children.map(children, tr => {
        if (!tr) return;
        let { children, ...props } = tr.props;

        children = Children.map(children, th => {
          if (!th) return th;
          let { select, children, ...props } = th.props;

          if (select) {
            if (!tr.key)
              throw new NoKeyDefinedError('key not defined on tbody row');
            children = (
              <input
                type="checkbox"
                onChange={e => onSelectChanged(e, tr.key)}
                checked={selected[tr.key] || false}
              />
            );
          }
          return <th.type {...props}>{children}</th.type>;
        });
        return <tr.type {...props}>{children}</tr.type>;
      });
    }

    return <i.type {...props}>{children}</i.type>;
  });

  const className = classNames('table-container', tmpClassName);
  return cloneElement(table, { className }, children);
};

const enhance = compose(
  defaultProps({
    idKey: 'id'
  }),
  withState('selectAll', 'setSelectAll', false),
  withHandlers({
    onSelectAllChanged: ({
      setSelectAll,
      idKey,
      onSelectedChanged,
      items
    }) => e => {
      const checked = e.target.checked;
      setSelectAll(checked);
      if (checked) {
        onSelectedChanged(
          chain(items)
            .map(i => [i[idKey], true])
            .fromPairs()
            .value()
        );
      } else {
        onSelectedChanged({});
      }
    },
    onSelectChanged: ({ onSelectedChanged, selected }) => (e, key) => {
      const checked = e.target.checked;
      const copy = { ...selected, [key]: checked };
      if (!copy[key]) delete copy[key];
      onSelectedChanged(copy);
    },
    onOrderingChanged: ({
      onOrderingChanged,
      ordering: tmpOrdering
    }) => column => {
      let ordering = null;
      if (!tmpOrdering || tmpOrdering.column !== column) {
        ordering = { column, direction: ASC_ORDERING };
      } else if (tmpOrdering.direction === DESC_ORDERING) {
        ordering = {};
      } else {
        ordering = { column, direction: DESC_ORDERING };
      }
      onOrderingChanged(ordering);
    }
  })
);

const EnhancedTableContainer = enhance(TableContainer);

EnhancedTableContainer.propTypes = {
  items: PropTypes.array,
  idKey: PropTypes.string,
  selected: PropTypes.object,
  onSelectedChanged: PropTypes.func,
  onOrderingChanged: PropTypes.func
};

export default EnhancedTableContainer;
