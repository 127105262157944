import React, { useEffect, useContext, useState, useMemo } from 'react';
import { UserContext, MembershipContext } from 'contexts';
import { usePageHelpers, useModal } from 'hooks';
import { Link } from 'react-router-dom';
import { PendingButton } from 'components/common';
import moment from 'moment';
import { compact } from 'lodash';
import intersperse from 'intersperse';
import UserAvatar from 'react-user-avatar';
import { NotificationManager } from 'react-notifications';

const STATUSES = {
  trialing: 'Trialing',
  active: 'Active',
  past_due: 'Payment overdue',
  canceled: 'Canceled',
  unpaid: 'Unpaid'
};

export const Account = ({ client, match }) => {
  const { user, logout } = useContext(UserContext);
  const { reload, membership, loading } = useContext(MembershipContext);
  const [profile, setProfile] = useState();
  const [cancelPending, setCancelPending] = useState();
  const [deletePending, setDeletePending] = useState();
  const { PageCard } = usePageHelpers();

  const {
    Modal,
    ModalBody,
    ModalHeader,
    toggle,
    ModalFooter,
    setIsOpen
  } = useModal();
  const {
    Modal: DeleteModal,
    toggle: toggleDeleteModal,
    setIsOpen: setIsDeleteOpen
  } = useModal();

  useEffect(
    () => {
      (async () => {
        const profile = await client.get('user_profile').get('data');
        setProfile(profile);
      })();
    },
    [user.id]
  );

  async function onCancel() {
    setCancelPending(true);
    try {
      await client.post('membership/cancel');
      NotificationManager.success(
        `Subscription successfully cancelled and will expire on ${moment(
          membership.current_period_end
        ).format('DD/MM/YYYY')}`
      );
      reload();
    } finally {
      setCancelPending(false);
      setIsOpen(false);
    }
  }

  async function onDelete() {
    setDeletePending(true);
    try {
      await client.post('me/delete_account');
      logout();
    } finally {
      setDeletePending(false);
      setIsDeleteOpen(false);
    }
  }

  const address = useMemo(
    () =>
      membership
        ? intersperse(
            compact([
              membership.address_line_1,
              membership.address_line_2,
              membership.city,
              membership.country,
              membership.postcode
            ]),
            <br />
          )
        : null,
    [membership]
  );

  if (loading || !profile) return null;

  const cancelModal = (
    <Modal>
      <ModalHeader>Cancel subscription</ModalHeader>
      {membership && membership.cancel_at_period_end ? (
        <>
          <ModalBody>
            Subscription has already been cancelled and will expire on{' '}
            {moment(membership.current_period_end).format('DD/MM/YYYY')}
          </ModalBody>
          <ModalFooter>
            <PendingButton onClick={() => setIsOpen(false)}>
              Close
            </PendingButton>
          </ModalFooter>
        </>
      ) : (
        <>
          <ModalBody>
            Are you sure you want to cancel your subscription?
          </ModalBody>
          <ModalFooter>
            <PendingButton onClick={onCancel} pending={cancelPending}>
              Confirm
            </PendingButton>
          </ModalFooter>
        </>
      )}
    </Modal>
  );

  const deleteModal = (
    <DeleteModal>
      <ModalHeader>Delete account</ModalHeader>
      <ModalBody>
        Are you sure you want to delete your account? This cannot be undone.
      </ModalBody>
      <ModalFooter>
        <PendingButton onClick={onDelete} pending={deletePending}>
          Confirm
        </PendingButton>
      </ModalFooter>
    </DeleteModal>
  );

  return (
    <PageCard className="account p-3">
      {cancelModal}
      {deleteModal}
      <div className="flex">
        <div className="w-2/5 account__contact_information">
          <div className="inline-block">
            <div className="flex flex-col items-center">
              <UserAvatar
                size="96"
                name={profile.user__full_name || profile.user__email}
                src={profile.profile_image__data}
              />
              <strong className="block mb-2">{profile.user__full_name}</strong>
              <div className="subheading self-start">Contact Information</div>
            </div>
            <p>
              <span className="fa fa-fw fa-envelope mr-2" />{' '}
              {profile.user__email}
              <br />
              {!!profile.contact_number && (
                <>
                  <span className="fa fa-fw fa-phone mr-2" />{' '}
                  {profile.contact_number}
                  <br />
                </>
              )}
            </p>
            <Link
              className="text-secondary font-medium"
              to={`${match.url}/profile`}
            >
              EDIT CONTACT DETAILS
            </Link>
          </div>
        </div>
        <div className="w-3/5 pl-3">
          <div>
            <div className="subheading">Subscription & Billing</div>
            {membership &&
            !profile.no_membership_required &&
            membership.managing_user_profile ? (
              <div>
                <strong>Subscription status:</strong> Part of{' '}
                {membership.managing_user_profile.full_name}'s plan.
              </div>
            ) : null}
            {profile.no_membership_required ? (
              <div>
                <div>
                  <strong>Subscription status:</strong> No subscription required
                </div>
                <div>
                  <strong>Number of Users:</strong> {profile.no_membership_num_users}
                </div>
                {profile.no_membership_num_users > 1 && (
                  <Link
                    className="link block"
                    to={`${match.url}/manage_users`}
                  >
                    MANAGE USERS
                  </Link>
                )}

              </div>
            ) : null}
            {!membership && !profile.no_membership_required ? (
              <Link
                className="link block"
                to={`${match.url}/setup_subscription`}
              >
                SETUP SUBSCRIPTION
              </Link>
            ) : null}
            {membership &&
            !profile.no_membership_required &&
            !membership.managing_user_profile ? (
              <div>
                <div className="mb-6">
                  <div>
                    <strong>Subscription status:</strong>{' '}
                    {STATUSES[membership.status]}
                  </div>
                  <div>
                    <strong>Number of Users:</strong> {membership.quantity}
                  </div>
                  {membership.status === 'trialing' && (
                    <div>
                      <strong>Trial ends:</strong>{' '}
                      {moment(membership.trial_end).format('DD/MM/YYYY')}
                    </div>
                  )}
                  {membership.status !== 'canceled' &&
                    !membership.cancel_at_period_end && (
                      <div>
                        <strong>Next billing date:</strong>{' '}
                        {moment(membership.current_period_end).format(
                          'DD/MM/YYYY'
                        )}
                      </div>
                    )}
                  {membership.status !== 'canceled' &&
                    membership.cancel_at_period_end && (
                      <div>
                        <strong>Cancellation date:</strong>{' '}
                        {moment(membership.current_period_end).format(
                          'DD/MM/YYYY'
                        )}
                      </div>
                    )}
                  {membership.is_active && (
                    <Link
                      className="link block"
                      to={`${match.url}/subscription_update`}
                    >
                      CHANGE NUMBER OF USERS
                    </Link>
                  )}
                  {membership.is_active && membership.quantity > 1 ? (
                    <Link
                      className="link block"
                      to={`${match.url}/manage_users`}
                    >
                      MANAGE USERS
                    </Link>
                  ) : null}
                </div>
                <div>
                  <div className="subheading">Billing Address</div>
                  <p>{address}</p>
                  <div>
                    <Link
                      className="text-secondary font-medium"
                      to={`${match.url}/billing_address`}
                    >
                      EDIT YOUR BILLING ADDRESS
                    </Link>
                  </div>
                  <div>
                    <Link
                      className="text-secondary font-medium"
                      to={`${match.url}/payment_method`}
                    >
                      EDIT YOUR PAYMENT METHOD
                    </Link>
                  </div>
                </div>
                <div>
                  {membership.is_active && (
                    <div className="mt-6">
                      <div className="subheading">Cancel your subscription</div>
                      <p>You can cancel your account at any time</p>
                      <div className="flex">
                        <PendingButton
                          className="link p-0 mr-8"
                          color="link"
                          onClick={toggle}
                        >
                          CANCEL SUBSCRIPTION
                        </PendingButton>
                        <PendingButton
                          className="link p-0"
                          color="link"
                          onClick={toggleDeleteModal}
                        >
                          DELETE ACCOUNT
                        </PendingButton>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </PageCard>
  );
};

export default Account;
