import React from 'react';
import {
  InputGroup as ReactstrapInputGroup,
  InputGroupAddon,
  InputGroupText
} from 'reactstrap';
import classNames from 'classnames';

export const InputGroup = ({
  children,
  prefixIcon,
  suffixIcon,
  suffixText,
  ...props
}) => (
  <ReactstrapInputGroup {...props}>
    {!!prefixIcon && (
      <InputGroupAddon addonType="prepend">
        <InputGroupText>
          <span className={classNames('fa', prefixIcon)} />
        </InputGroupText>
      </InputGroupAddon>
    )}
    {children}
    {!!suffixIcon && (
      <InputGroupAddon addonType="append">
        <InputGroupText>
          <span className={classNames('fa', suffixIcon)} />
        </InputGroupText>
      </InputGroupAddon>
    )}
    {!!suffixText && (
      <InputGroupAddon addonType="append">{suffixText}</InputGroupAddon>
    )}
  </ReactstrapInputGroup>
);

export default InputGroup;
