import React, { useState } from 'react';
import { useForm } from 'hooks';
import {
  FormGroup,
  required,
  SubmitButton,
  Page,
  Warning
} from 'components/common';
import { isEmail } from 'validator';
import { Errors } from 'react-redux-form';
import { Alert } from 'reactstrap';
import { get } from 'lodash';
import { Link } from 'react-router-dom';

export const Register = ({ client }) => {
  const [success, setSuccess] = useState();

  const { Form, Control, dispatch, actions } = useForm({
    initialState: {
      email: '',
      confirm_password: '',
      password: ''
    }
  });

  if (success) {
    return (
      <Alert color="success" className="text-center">
        <span className="fa fa-check mr-1" />
        Your account has been successfully created, you may now{' '}
        <Link to="/login" replace>
          sign in
        </Link>
      </Alert>
    );
  }

  async function onSubmit(x) {
    dispatch(actions.resetErrors('server'));

    try {
      await client.post('/register', x);
      setSuccess(true);
    } catch (ex) {
      const code = get(ex, 'response.data.code');

      if (code === 'invalid') {
        const data = ex.response.data;
        dispatch(actions.setErrors('server.email', data.email));
        dispatch(actions.setErrors('server.password', data.password));
      } else {
        throw ex;
      }
    } finally {
      dispatch(actions.setPending('local', false));
    }
  }

  return (
    <Page title="Register" className="max-w-md mx-auto">
      <Form
        validators={{
          '': {
            passwordsMatch: ({ password, confirm_password }) => {
              if (!password || !confirm_password) return true;
              return password === confirm_password;
            }
          },
          email: isEmail,
          password: required,
          confirm_password: required
        }}
        onSubmit={onSubmit}
      >
        <FormGroup label="Email" model=".email">
          <Control.text className="form-control" model=".email" />
          <Errors model="server.email" component={Warning} />
        </FormGroup>

        <FormGroup label="Password" model=".password">
          <Control.input
            type="password"
            className="form-control"
            model=".password"
          />
        </FormGroup>

        <FormGroup label="Confirm Password" model=".confirm_password">
          <Control.input
            type="password"
            className="form-control"
            model=".confirm_password"
          />
          <Errors
            model="local"
            messages={{
              passwordsMatch: 'Passwords do not match'
            }}
            component={Warning}
          />
          <Errors model="server.password" component={Warning} />
        </FormGroup>

        <div className="flex justify-end">
          <SubmitButton>
            <span className="fa fa-sign-in mr-2" />
            Register
          </SubmitButton>
        </div>
      </Form>
    </Page>
  );
};

export default Register;
