import React, { useState, useContext, useMemo } from 'react';
import { ReactReduxContext } from 'react-redux';
import { Control, getModel, actions } from 'react-redux-form';
import resolveModel from 'react-redux-form/lib/utils/resolve-model';
import { Editor } from 'react-draft-wysiwyg';

export const ConnectedEditor = ({
  onBlur,
  model,
  context,
  mapProps,
  ...props
}) => {
  const [contentState, setContentState] = useState();
  const { store, storeState } = useContext(context || ReactReduxContext);

  const modelString = useMemo(
    () =>
      getModel(
        typeof model === 'function' ? model(storeState) : model,
        storeState
      ),
    [model, storeState]
  );

  return (
    <Control.custom
      model={modelString}
      component={Editor}
      onContentStateChange={setContentState}
      onBlur={e => {
        store.dispatch(actions.change(modelString, contentState));
        if (onBlur) onBlur(e);
      }}
      mapProps={{
        defaultContentState: ({ modelValue }) => modelValue,
        ...mapProps
      }}
      {...props}
    />
  );
};

export default resolveModel(ConnectedEditor);
