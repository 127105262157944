import React from 'react';
import { FormGroup, Input } from 'reactstrap';
import { Control } from 'react-redux-form';
import { translate } from 'services/i18n';
import { useForm } from 'hooks';
import classNames from 'classnames';

export const TableSearch = ({
  onChange,
  t,
  value,
  debounce = 1000,
  tReady,
  i18nOptions,
  defaultNS,
  reportNS,
  className,
  ...props
}) => {
  const { Form } = useForm();

  return (
    <FormGroup {...props} className={classNames('table-search', className)}>
      <Form component="div" onChange={({ search }) => onChange(search)}>
        <div className="input-group input-group-sm">
          <Control.text
            model=".search"
            debounce={debounce}
            component={Input}
            placeholder={'Search...'}
          />
          <div className="input-group-append">
            <span className="fa fa-search input-group-text" />
          </div>
        </div>
      </Form>
    </FormGroup>
  );
};

export const enhance = translate();

export default enhance(TableSearch);
