import React, { useContext, useMemo } from 'react';
import { Switch, Route as BaseRoute, Redirect, Link } from 'react-router-dom';
import { isUndefined } from 'lodash';
import { UserContext, MembershipContext } from 'contexts';
import {
  Dashboard,
  Account,
  Help,
  Resources,
  PracticeTool,
  Courses,
  Login,
  Settings,
  Register,
  Users,
  ManageCourses,
  PasswordReset,
  AcceptInvite,
  Invite
} from './pages';
import { Card, CardBody, CardTitle } from 'reactstrap';
import { ApiClient } from 'services';

const Forbidden = () => (
  <Card>
    <CardBody>
      <CardTitle>
        <span className="fa fa-exclamation-circle fa-fw text-danger" />{' '}
        Forbidden
      </CardTitle>
      You do not have permission to access this page.
    </CardBody>
  </Card>
);

const MembershipStatus = ({ membership }) => {
  let title = null;
  let body = null;

  if (!membership || membership.is_inactive) {
    title = 'Subscription required';
    body = (
      <div>
        A subscription is required to view this page,{' '}
        <Link to="/account/setup_subscription" className="text-secondary">
          SETUP A SUBSCRIPTION NOW
        </Link>
      </div>
    );
  } else if (membership.is_locked) {
    title = 'Subscription payment overdue';
    body = (
      <div>
        Payment could not be taken and your subscription is overdue. Please
        update your payment method from the{' '}
        <Link to="/account" className="link">
          ACCOUNT
        </Link>{' '}
        page.
      </div>
    );
  }

  return (
    <Card>
      <CardBody>
        <CardTitle>
          <span className="fa fa-exclamation-circle fa-fw text-info" /> {title}
        </CardTitle>
        {body}
      </CardBody>
    </Card>
  );
};

export const NotFound = () => (
  <Card>
    <CardBody>
      <CardTitle>
        <span className="fa fa-question-circle text-info fa-fw" /> Not Found
      </CardTitle>
      The page you have requested could not be found.
    </CardBody>
  </Card>
);

export const Route = ({
  path,
  component: Component,
  test,
  exact,
  checkMembership = false,
  checkManagingUser = false
}) => {
  const client = useMemo(() => ApiClient(), []);
  const { membership, loading, profile } = useContext(MembershipContext);

  return (
    <BaseRoute
      path={path}
      exact={exact}
      render={props => {
        if (!isUndefined(test) && !test) {
          return <Forbidden />;
        }
        if (checkMembership) {
          if (loading) return null;
          if (
            !profile.no_membership_required &&
            (!membership || membership.is_locked || membership.is_inactive)
          ) {
            return <MembershipStatus membership={membership} />;
          }
          if (checkManagingUser) {
            if (!((membership && membership.quantity > 1) || (profile.no_membership_required && profile.no_membership_num_users > 1))) {
              return <Forbidden />
            }
          }
        }

        return <Component client={client} {...props} />;
      }}
    />
  );
};

export const UserRoute = props => {
  const { user, isUser } = useContext(UserContext);

  if (!user) {
    return <Redirect to="/login" />;
  }

  return <Route test={isUser} {...props} />;
};

export const MembershipRoute = props => {
  return <UserRoute checkMembership {...props} />;
};

export const ManagingUserRoute = props => {
  return <MembershipRoute checkManagingUser {...props} />;
};

export const AdminRoute = props => {
  const { isAdmin } = useContext(UserContext);

  return <UserRoute test={isAdmin} {...props} />;
};

export const Routes = () => {
  const { isAdmin } = useContext(UserContext);
  let redirect = '/dashboard';
  if (isAdmin) redirect = '/manage_users';

  return (
    <Switch>
      <UserRoute
        exact
        path="/dashboard"
        component={Dashboard.Dashboard}
        checkMembership={false}
      />
      <UserRoute exact path="/account" component={Account.Account} />
      <UserRoute
        exact
        path="/account/subscription_update"
        component={Account.UpdateMembership}
      />

      <UserRoute
        exact
        path="/account/payment_method"
        component={Account.UpdatePaymentMethod}
      />
      <UserRoute
        exact
        path="/account/billing_address"
        component={Account.UpdateBillingAddress}
      />
      <ManagingUserRoute
        exact
        path="/account/manage_users"
        component={Account.ManageSubscriptions}
      />
      <UserRoute
        exact
        path="/account/setup_subscription"
        component={Account.SetupSubscription}
      />

      <UserRoute exact path="/account/profile" component={Account.Profile} />
      <MembershipRoute exact path="/courses" component={Courses.List} />
      <MembershipRoute exact path="/courses/:id" component={Courses.View} />
      <MembershipRoute
        exact
        path="/courses/:course/modules/:id"
        component={Courses.Modules.View}
      />
      <MembershipRoute
        exact
        path="/courses/:course/modules/:module/content/:id"
        component={Courses.Modules.Content.View}
      />
      <MembershipRoute
        exact
        path="/resources"
        component={Resources.Resources}
      />
      <UserRoute exact path="/help" component={Help.Help} />
      <MembershipRoute exact path="/accq_tool" component={PracticeTool} />
      <AdminRoute exact path="/manage_users" component={Users.List} />
      <AdminRoute exact path="/manage_users/:id" component={Users.Edit} />
      <AdminRoute exact path="/manage_courses" component={ManageCourses.List} />
      <AdminRoute
        exact
        path="/manage_courses/:id"
        component={ManageCourses.Edit}
      />
      <AdminRoute
        exact
        path="/manage_courses/:course/modules/:id"
        component={ManageCourses.Modules.Edit}
      />
      <AdminRoute
        exact
        path="/manage_courses/:course/modules/:module/sections/:id"
        component={ManageCourses.Modules.Sections.Edit}
      />
      <AdminRoute
        exact
        path="/manage_courses/:course/modules/:module/sections/:section/content/:id"
        component={ManageCourses.Modules.Sections.SectionContent.Edit}
      />
      <AdminRoute exact path="/settings" component={Settings} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/register" component={Register} />
      <Route exact path="/invite/:uuid(.{36})" component={AcceptInvite} />
      <Route
        exact
        path="/password_reset_request"
        component={PasswordReset.PasswordResetRequest}
      />
      <Route
        exact
        path="/password_reset/:token"
        component={PasswordReset.PasswordReset}
      />
      <ManagingUserRoute
        exact
        path="/account/manage_users/invite"
        component={Invite}
      />
      <Redirect from="/" to={redirect} exact />
      <Route component={NotFound} />
    </Switch>
  );
};

export default Routes;
