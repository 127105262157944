import decode from 'jwt-decode';
import moment from 'moment';
import ApiClient from './ApiClient';
import { filter, forEach } from 'lodash';
import Cookies from 'js-cookie';

const TOKEN_KEY = 'accqToken';
const TOKEN_COOKIE_DOMAIN = process.env.REACT_APP_TOKEN_COOKIE_DOMAIN;

export const getToken = () => Cookies.get(TOKEN_KEY) || null;

let subscriptions = [];

const notify = () => {
  forEach(subscriptions, i => i());
};

export const clearToken = () => {
  Cookies.remove(TOKEN_KEY, {
    domain: TOKEN_COOKIE_DOMAIN,
  });
  notify();
};

export const login = ({ email, password }) => {
  const client = ApiClient({
    bypassAuthCheck: true,
    redirectOnUnauthorized: false
  });
  clearToken();
  return client
    .post('/auth', {
      email: email.toLowerCase(),
      password
    })
    .then(resp => {
      Cookies.set(TOKEN_KEY, resp.data.token, {
        expires: 7,
        domain: TOKEN_COOKIE_DOMAIN
      })
      notify();
    });
};

export const subscribe = cb => {
  subscriptions.push(cb);

  return () => {
    subscriptions = filter(subscriptions, i => i !== cb);
  };
};

export const loggedIn = () => {
  const token = getToken();
  if (!token) return false;
  let clear = false;
  let decoded = null;

  try {
    decoded = decode(token);
    if (decoded.exp && moment(decoded.exp * 1000).isSameOrBefore())
      clear = true;
  } catch (err) {
    clear = true;
  }

  if (clear) {
    clearToken();
    return false;
  }
  return decoded;
};

export const username = () => {
  const token = getToken();
  if (!token) return '';

  try {
    const decoded = decode(token);
    return decoded.username;
  } catch (err) {
    return '';
  }
};

export const decodeToken = () => {
  const token = getToken();
  if (!token) return null;

  try {
    return decode(token);
  } catch (err) {
    return null;
  }
};

export const getRole = () => {
  const token = getToken();
  if (!token) return '';

  try {
    const decoded = decode(token);
    return decoded.role;
  } catch (err) {
    return '';
  }
};

export default {
  login,
  loggedIn,
  username,
  getToken,
  clearToken,
  getRole,
  decodeToken
};
