import React, { useEffect, useState, useCallback, useContext } from 'react';
import { useForm } from 'hooks';
import { Page, FormGroup, required, SubmitButton } from 'components/common';
import Select from 'react-select';
import { NotificationManager } from 'react-notifications';
import { MembershipContext } from 'contexts';

import { vatFormats } from './VatFormats';
import { getData } from 'country-list';

const countryOptions = getData().map(({ name, code }) => ({
  key: code,
  label: name,
  value: code
}));

export const Membership = ({ client, history }) => {
  const {
    Form,
    Control,
    loaded,
    setValue,
    dispatch,
    actions,
    value
  } = useForm();
  const { reload } = useContext(MembershipContext);
  const [membership, setMembership] = useState();

  useEffect(() => {
    (async () => {
      const [membership] = await Promise.all([
        client.get('membership').get('data')
      ]);
      setMembership(membership);
      setValue({
        vat_number: '',
        type: 'Monthly'
      });
    })();
  }, []);

  async function onSubmit(x) {
    try {
      await client.post('membership/setup', x);
      reload();
      history.replace('/account');
      NotificationManager.success('Subscription successfully setup');
    } finally {
      dispatch(actions.setPending('local', false));
    }
  }

  if (!loaded) return null;

  const Container = useCallback(
    props => <Page title="Subscription" {...props} />,
    []
  );

  if (membership.id) {
    return <Container>You already have an active subscription.</Container>;
  }

  const showVat = value.country && vatFormats[value.country];

  return (
    <Container>
      <Form
        validators={{
          type: required,
          address_line_1: required,
          city: required,
          postcode: required,
          country: required,
          vat_number: v => (showVat ? required(v) : true)
        }}
        onSubmit={onSubmit}
      >
        <FormGroup label="Billing Address" model=".address_line_1">
          <Control.text
            placeholder="Address Line 1"
            model=".address_line_1"
            className="form-control"
          />
        </FormGroup>
        <FormGroup label="" model=".address_line_2">
          <Control.text
            placeholder="Address Line 2"
            model=".address_line_2"
            className="form-control"
          />
        </FormGroup>
        <FormGroup label="" model=".city">
          <Control.text
            placeholder="City"
            model=".city"
            className="form-control"
          />
        </FormGroup>
        <FormGroup label="" model=".postcode">
          <Control.text
            placeholder="Postcode"
            model=".postcode"
            className="form-control"
          />
        </FormGroup>
        <FormGroup label="" model=".country">
          <Control.text
            component={Select}
            options={countryOptions}
            simpleValue
            model=".country"
            clearable={false}
            placeholder="Country"
            onChange={v => {
              if (!vatFormats[v]) {
                dispatch(actions.change('local.vat_number', ''));
              }
            }}
          />
        </FormGroup>
        {showVat && (
          <FormGroup label="VAT Number *" model=".vat_number">
            <Control.text model=".vat_number" className="form-control" />
          </FormGroup>
        )}

        <div className="flex justify-end">
          <SubmitButton>Pay & Setup Subscription</SubmitButton>
        </div>
      </Form>
    </Container>
  );
};

export default Membership;
