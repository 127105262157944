import React, { useContext, useState, useEffect, useMemo } from 'react';
import { Button, ModalHeader, ModalBody } from 'reactstrap';
import classNames from 'classnames';
import { PracticeToolContext, PracticeToolProvider } from './reducer';
import {FormGroup, PendingButton} from 'components/common';
import { useModal, useForm } from 'hooks';
import shortid from 'shortid';
import { NotificationManager } from 'react-notifications';
import Select from 'react-select';
import './styles.scss';
import numeral from 'numeral';
import { get, range, parseInt, isFinite, orderBy } from 'lodash';
import { useMembership } from 'hooks';
import styles from './styles.module.scss';
import {MembershipContext} from "../../contexts";
import useSWR from "swr";

function useToolSettings({ client }) {
  return useSWR('tool_settings', url => client.get(url).get('data'), {
    fallbackData: {
      disable_pl_balance_sheet: false
    }
  });
}

const formatNumber = (v, parenthesesLoss) => {
  if (parenthesesLoss) {
    const value = parseFloat(v);

    if (isFinite(value) && value < 0) {
      return `(${numeral(Math.abs(value)).format('0,0')})`;
    }
  }

  return v ? numeral(v).format('0,0') : v;
};

const QUADRANTS = {
  EXPENSES: {
    heading: (
      <>
        Accounts with period end Debit (DR) Balances{' '}
        <span className="fa fa-arrow-down" />
      </>
    ),
    quadrant: 'expenses',
    subTitle: 'Day-to-day running costs',
    color: 'steelblue',
    creditsArePositive: false,
    defaultAccounts: [
      'Trade Purchases',
      'Discounts Given',
      'General Expenses',
      'Depreciation',
      'Bad Debt Charge',
      'Loan Interest',
      'Dividends',
      'Taxation'
    ]
  },
  REVENUE: {
    quadrant: 'revenue',
    heading: (
      <>
        Accounts with period end Credit (CR) Balances{' '}
        <span className="fa fa-arrow-down" />
      </>
    ),
    creditsArePositive: true,
    subTitle: 'Sales, Fees, Income, Commissions, etc.',
    color: 'firebrick',
    defaultAccounts: [
      'Trade Sales',
      'Discounts Received',
      'Other Income',
      'Sale of Fixed Assets'
    ]
  },
  ASSETS: {
    quadrant: 'assets',
    creditsArePositive: false,
    subTitle: (
      <>
        Things and amounts we <span className="font-medium underline">OWN</span>
      </>
    ),
    color: 'teal-2',
    defaultAccounts: [
      'Bank/Cash',
      'Trade Debtors',
      'Bad Debt Provision',
      'Fixed Assets',
      'Accumulated Depreciation',
      'Stocks and WIP',
      'Prepayments',
      'Other Debtors'
    ]
  },
  'LIABILITIES/EQUITY': {
    quadrant: 'equity',
    creditsArePositive: true,
    subTitle: (
      <>
        Things and amounts we <span className="font-medium underline">OWE</span>
      </>
    ),
    color: 'goldenrod',
    defaultAccounts: [
      'Directors Loans',
      'Trade Creditors',
      'Accruals',
      'Other Creditors/Loans',
      'Dividends Payable',
      'Taxation Payable',
      'Share Capital',
      'Accumulated Profit'
    ]
  }
};

const AccountInfo = ({
  className,
  active,
  onClick,
  activeColor,
  account,
  ...props
}) => {
  const activeClassName = `border-2 border-${activeColor}`;

  return (
    <div
      className={classNames(
        ' border-2 bg-lavender text-darkslategray flex justify-between px-2 py-1 text-sm rounded-lg border border-solid',
        {
          'border border-grey': !active,
          [activeClassName]: active,
          'cursor-pointer': onClick
        },
        className
      )}
      onClick={onClick}
      {...props}
    >
      <span className="mr-3 text-nowrap">{account.name}:</span>
      <span style={{ minWidth: '5rem' }}>€{formatNumber(account.total)}</span>
    </div>
  );
};

const Ledger = ({
  type,
  accounts,
  setIsLedgerOpen,
  selectedAccountIndex,
  setSelectedAccountIndex
}) => {
  const { color, quadrant, creditsArePositive } = QUADRANTS[type];
  const {
    state,
    actions: { addTransaction, removeTransaction, removeAccount }
  } = useContext(PracticeToolContext);
  const year = state.years[state.selectedYear];
  const selectedAccount = accounts[selectedAccountIndex];
  const transactions = selectedAccount.transactions;
  const {
    Form,
    Control,
    FormGroup,
    SubmitButton,
    dispatch,
    actions,
    validators: { required, isInteger }
  } = useForm({
    initialState: {
      ac_name: '',
      ref: '',
      debit: undefined,
      credit: undefined
    }
  });
  const isLocked = year.status === 'REVIEW' || year.status === 'FINAL';

  const fillRows = useMemo(
    () => {
      const fill = 15 - transactions.length;

      if (fill <= 0) return null;

      return range(fill).map(i => (
        <tr key={i}>
          <td>&nbsp;</td>
          <td />
          <td />
          <td />
          <td />
          <td />
        </tr>
      ));
    },
    [transactions.length]
  );

  return (
    <div className="flex text-darkslategray">
      <div className="pr-3 border-0 border-r border-grey border-solid">
        <div>Accounts</div>
        <div>
          {accounts.map((i, idx) => (
            <AccountInfo
              onClick={() => setSelectedAccountIndex(idx)}
              activeColor={color}
              active={i === selectedAccount}
              account={i}
              key={i.name}
              className="mb-1"
            />
          ))}
        </div>
      </div>
      <div className="pl-3">
        <div className="flex justify-between">
          <div className="mr-3">
            Nominal/General Ledger Account Name:{' '}
            <strong>{selectedAccount.name}</strong>
          </div>
          <Button
            onClick={() => {
              setIsLedgerOpen(false);
              removeAccount(quadrant, selectedAccountIndex);
            }}
            disabled={isLocked}
            color="link"
            className="text-secondary p-0"
          >
            DELETE ACCOUNT
          </Button>
        </div>
        <div className="mt-3">
          <div>Add Transaction</div>

          <Form
            validators={{
              ac_name: required,
              ref: required,
              debit: v => (v ? isInteger(v, { min: 0 }) : true),
              credit: v => (v ? isInteger(v, { min: 0 }) : true)
            }}
            onSubmit={transaction => {
              addTransaction(quadrant, selectedAccountIndex, {
                ...transaction,
                key: shortid()
              });
              dispatch(actions.reset('local'));
            }}
          >
            <div className="flex mb-3">
              <FormGroup
                className="mr-1 mb-0"
                model=".ac_name"
                disableUntouchedState
              >
                <Control.text
                  placeholder="A/C Name"
                  model=".ac_name"
                  autoComplete="off"
                  className="form-control form-control-sm"
                />
              </FormGroup>
              <FormGroup
                className="mr-1 mb-0"
                model=".ref"
                disableUntouchedState
              >
                <Control.text
                  placeholder="Ref"
                  model=".ref"
                  autoComplete="off"
                  className="form-control form-control-sm"
                />
              </FormGroup>
              <FormGroup
                className="mr-1 mb-0"
                model=".debit"
                disableUntouchedState
              >
                <Control.text
                  placeholder="Debit"
                  model=".debit"
                  autoComplete="off"
                  className="form-control form-control-sm"
                />
              </FormGroup>
              <FormGroup
                className="mr-1 mb-0"
                model=".credit"
                disableUntouchedState
              >
                <Control.text
                  placeholder="Credit"
                  model=".credit"
                  autoComplete="off"
                  className="form-control form-control-sm"
                />
              </FormGroup>
              <SubmitButton
                size="sm"
                disabled={isLocked}
                className="rounded-lg"
              >
                ADD
              </SubmitButton>
            </div>
          </Form>
        </div>
        <table className="table table-sm table-bordered text-sm ledger-table">
          <thead>
            <tr>
              <th className="text-center bg-lavender">A/C Name</th>
              <th className="text-center bg-lavender">Ref</th>
              <th className="text-right bg-lavender">
                Debit {creditsArePositive ? '(-)' : '(+)'}
              </th>
              <th className="text-right bg-lavender">
                Credit {creditsArePositive ? '(+)' : '(-)'}
              </th>
              <th className="text-right bg-lavender">Balance</th>
              <th className="bg-lavender" />
            </tr>
          </thead>
          <tbody>
            {transactions.map((i, idx) => (
              <tr key={i.key}>
                <td>{i.ac_name}</td>
                <td>{i.ref}</td>
                <td className="text-right">{i.debit ? i.debit : null}</td>
                <td className="text-right">{i.credit ? i.credit : null}</td>
                <td className="text-right">{i.balance}</td>
                <td className="text-center">
                  {year.status !== 'FINAL' &&
                  idx === transactions.length - 1 &&
                  !i.locked ? (
                    <Button
                      color="link"
                      disabled={isLocked}
                      onClick={() => {
                        removeTransaction(quadrant, selectedAccountIndex, idx);
                      }}
                      className="p-0 text-secondary"
                    >
                      <span className="fa fa-times" />
                    </Button>
                  ) : null}
                </td>
              </tr>
            ))}
            {fillRows}
          </tbody>
        </table>
        <div className="flex justify-end mt-3">
          <Button
            onClick={() => {
              setIsLedgerOpen(false);
            }}
            color="link"
            className="p-0"
          >
            CLOSE
          </Button>
        </div>
      </div>
    </div>
  );
};

const quadrantContainerStyle = { width: '30rem', height: '21rem' };
const lowerQuadrantContainerStyle = { width: '30rem', height: '19rem' };

const Quadrant = ({ type }) => {
  const { color, subTitle, quadrant, defaultAccounts } = QUADRANTS[type];
  const defaultAccountOptions = useMemo(
    () => [
      ...[{ label: 'Add new account', value: 'new' }],
      ...defaultAccounts.map(i => ({ label: i, value: i }))
    ],
    [defaultAccounts]
  );
  const {
    state,
    actions: { addAccount }
  } = useContext(PracticeToolContext);
  const year = state.years[state.selectedYear];
  const accounts = year.accounts[quadrant];
  const {
    isOpen: isAddAccountOpen,
    setIsOpen: setIsAddAccountOpen,
    toggle: toggleAddAccount,
    Modal: AddAccountModal,
    ModalHeader,
    ModalBody
  } = useModal();
  const {
    isOpen: isLedgerOpen,
    setIsOpen: setIsLedgerOpen,
    Modal: LedgerModal
  } = useModal();
  const {
    Form,
    Control,
    FormGroup,
    SubmitButton,
    dispatch,
    actions,
    value
  } = useForm();
  const [selectedAccountIndex, setSelectedAccountIndex] = useState();
  const isLocked = year.status === 'REVIEW' || year.status === 'FINAL';
  const closeBtn = (
    <Button
      color="link"
      className="text-white"
      onClick={() => setIsLedgerOpen(false)}
    >
      <span className="fa fa-close" />
    </Button>
  );

  return (
    <div className="quadrant flex-grow flex h-full">
      {isLedgerOpen && (
        <LedgerModal
          size="lg"
          style={{ maxWidth: 800 }}
          contentClassName="border-0 shadow-lg"
          backdrop={false}
        >
          <ModalHeader className={`bg-${color} text-white`} close={closeBtn}>
            <div className="leading-none">
              {type}
              <br />
              <span className="text-sm">{subTitle}</span>
            </div>
          </ModalHeader>
          <ModalBody>
            <Ledger
              setIsLedgerOpen={setIsLedgerOpen}
              setSelectedAccountIndex={setSelectedAccountIndex}
              selectedAccountIndex={selectedAccountIndex}
              type={type}
              accounts={accounts}
            />
          </ModalBody>
        </LedgerModal>
      )}
      {isAddAccountOpen && (
        <AddAccountModal contentClassName="border-0 shadow-lg" backdrop={false}>
          <div className={`modal-header bg-${color} text-white`}>
            <h5 className="modal-title text-xl">
              Add New/Open Account: {type}
            </h5>
          </div>
          <ModalBody>
            <Form
              validators={{ '': ({ name, selected }) => name || selected }}
              onSubmit={({ name, selected }) => {
                addAccount(quadrant, {
                  name: name || selected,
                  transactions: [],
                  total: '0'
                });
                setIsAddAccountOpen(false);
              }}
            >
              <FormGroup model=".selected" className="mb-1">
                <Control.text
                  component={Select}
                  options={defaultAccountOptions}
                  simpleValue
                  changeAction={(m, v) => {
                    dispatch(actions.change(m, v));
                    dispatch(actions.change('local.name', ''));
                  }}
                  placeholder="Choose from list"
                  model=".selected"
                />
              </FormGroup>
              {value.selected && value.selected === 'new' ? (
                <FormGroup model=".name">
                  <Control.text
                    autoComplete="off"
                    model=".name"
                    className="form-control"
                    placeholder="Type account name here..."
                  />
                </FormGroup>
              ) : null}
              <div className="flex justify-between mt-3">
                <Button color="link" onClick={() => setIsAddAccountOpen(false)}>
                  Cancel
                </Button>
                {(value.selected === 'new' && value.name) ||
                (value.selected && value.selected !== 'new') ? (
                  <SubmitButton color="link" className="text-secondary">
                    {value.selected === 'new' ? 'Add Account' : 'Open Account'}
                  </SubmitButton>
                ) : null}
              </div>
            </Form>
          </ModalBody>
        </AddAccountModal>
      )}
      <div className={`card border-${color} shadow-md flex-grow`}>
        <div
          className={`card-header text-white print:bg-invisible bg-${color}`}
        >
          <div className="flex justify-between">
            <div className="leading-tight">
              <span className="font-medium text-lg font-white">{type}</span>
              <br />
              <span className="text-sm">{subTitle}</span>
            </div>
            <Button
              color="lavender"
              size="sm"
              disabled={isLocked}
              onClick={() => {
                dispatch(actions.reset('local'));
                toggleAddAccount();
              }}
              className="text-darkslategrey font-medium text-xs px-3  rounded-lg"
            >
              Add New/Open Account
            </Button>
          </div>
        </div>
        <div className="card-body h-full pt-2 overflow-y-auto">
          {accounts.map((i, idx) => (
            <AccountInfo
              onClick={() => {
                setSelectedAccountIndex(idx);
                setIsLedgerOpen(true);
              }}
              account={i}
              key={i.name}
              className="mb-1"
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const euroCellStyle = { minWidth: '5rem', maxWidth: '5rem' };

const DownIcon = () => <span className="fa fa-arrow-down" />;
const RotatedDownIcon = () => <span className="fa fa-arrow-right" />;

const ProfitLossRows = ({ value, fill = 4 }) => {
  const filledValue = useMemo(
    () => {
      const fillEmpty = Math.max(fill - (value ? value.length : 0), 0);

      return [
        ...(value || []),
        ...range(fillEmpty).map(i => ({
          name: <span>&nbsp;</span>
        }))
      ];
    },
    [value]
  );
  return filledValue.map((i, idx) => (
    <tr key={idx}>
      <td>{i.name}</td>
      <td className="text-right">{formatNumber(i.total)}</td>
    </tr>
  ));
};

const BalanceSheetRows = ProfitLossRows;

const YearTab = ({ active, year, disabled, muted, onClick }) => {
  return (
    <li className="nav-item">
      <button
        className={classNames(
          'btn-link pt-2 px-8 pb-2 nav-link cursor-pointer text-darkslategray',
          {
            active,
            'opacity-50': muted
          }
        )}
        disabled={disabled}
        onClick={onClick}
      >
        YEAR {year}
      </button>
    </li>
  );
};

const Open = ({ Modal, setIsOpen, client, onOpen, onRemove }) => {

  const { Form, SubmitButton, value, dispatch, actions } = useForm({
    initialState: {
      toolStates: null,
      selected: null,
    }
  });

  useEffect(() => {
    (async () => {
      const toolStates = await client.get('tool_state').get('data').then(i => orderBy(i, 'name'));
      dispatch(actions.change('local.toolStates', toolStates));
    })();
  }, []);

  async function onSubmit({ selected }) {
    try {
      await Promise.try(() => onOpen(selected));
      setIsOpen(false);
    } finally {
      dispatch(actions.setPending('local', false));
    }
  }

  return (
    <Modal>
      <ModalHeader>Open</ModalHeader>
      <ModalBody>
        {!value.toolStates && (
          <div>
            <span className="fa fa-fw fa-spin fa-spinner" /> Loading...
          </div>
        )}
        <Form validators={{
          selected: v => !!v,
        }} onSubmit={onSubmit}>
          <div>
            {value.toolStates && value.toolStates.map(i => (
              <div className="flex" key={i.id}>
                <div className={classNames(
                  'bg-lavender flex-grow text-darkslategray px-2 py-1 mb-2 text-sm rounded-lg border border-solid cursor-pointer',
                  {
                    'border-3 border-blue': i === value.selected,
                    'border border-grey': i !== value.selected,
                  }
                )} key={i.id} onClick={() => {
                  dispatch(actions.change('local.selected', i));
                }}>
                  {i.name === 'default' ? 'Default' : i.name}
                </div>
                <div className="ml-1">
                  <Button color="link" onClick={async () => {
                    await onRemove(i);
                    dispatch(actions.filter('local.toolStates', j => j.id !== i.id));
                    if (value.selected && value.selected.id === i.id) {
                      dispatch(actions.change('local.selected', null));
                    }
                  }}>
                    <span className="fa fa-trash fa-fw text-danger" />
                  </Button>
                </div>
              </div>
            ))}
          </div>
          <div className="justify-between flex mt-4">
            <Button
              color="link"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              CANCEL
            </Button>
            {!!value.selected && (
              <SubmitButton
                color="link"
                className="text-secondary"
              >
                OPEN
              </SubmitButton>
            )}
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
}

const Settings = ({ Modal, setIsOpen, value, onSave }) => {
  const { Form, FormGroup, SubmitButton, Control, dispatch, actions } = useForm({
    initialState: {
      disable_pl_balance_sheet: value.disable_pl_balance_sheet
    }
  });

  async function onSubmit(x) {
    try {
      await onSave(x);
      setIsOpen(false);
    } finally {
      dispatch(actions.setPending('local', false));
    }
  }

  return (
    <Modal>
      <ModalHeader>Settings</ModalHeader>
      <ModalBody>
        <Form onSubmit={onSubmit}>
          <FormGroup>
            <FormGroup htmlFor="disable_pl_balance_sheet" check label="Hide Profit & Loss and Balance Sheet view" model=".disable_pl_balance_sheet">
              <Control.checkbox
                model=".disable_pl_balance_sheet"
                className="form-check-input"
                id="disable_pl_balance_sheet"
              />
            </FormGroup>
          </FormGroup>
          <div className="justify-between flex mt-4">
            <Button
              color="link"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              CANCEL
            </Button>
            <SubmitButton
              color="link"
              className="text-secondary"
            >
              Save Settings
            </SubmitButton>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
}

const SaveAs = ({ Modal, setIsOpen, value, onSave, onSaveNew }) => {
  const { Form, field, FormGroup, SubmitButton, value: values, Control, dispatch, actions } = useForm({
    initialState: {
      name: value.name === 'default' ? '' : value.name,
      id: value.name === 'default' ? undefined : value.id,
    }
  });

  const isSaveAsNew = useMemo(() => field.initialValue.name === '' || field.initialValue.name !== values.name, [
    field.initialValue.name,
    values.name
  ]);

  async function onSubmit({ name }) {
    try {
      if (isSaveAsNew) {
        await onSaveNew(name);
      } else {
        await onSave();
      }
      setIsOpen(false);
    } finally {
      dispatch(actions.setPending('local', false));
    }
  }

  return (
    <Modal>
      <ModalHeader>Save As</ModalHeader>
      <ModalBody>
        <Form validators={{
          name: v => v,
        }} onSubmit={onSubmit}>
          <FormGroup model=".name">
            <Control.text model=".name" placeholder="Type a descriptive name..." className="form-control" />
          </FormGroup>
          <div className="justify-between flex mt-4">
            <Button
              color="link"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              CANCEL
            </Button>
            <SubmitButton
              color="link"
              className="text-secondary"
            >
              {isSaveAsNew ? 'SAVE AS' : 'SAVE'}
            </SubmitButton>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
}



export const PracticeTool = ({ history, onRemove, onSaveNew, client, onReset: passedOnReset, onSave, value, onOpen }) => {
  const {
    state,
    actions: { setSelectedYear, finalizeYear, setStatus, undo, redo }
  } = useContext(PracticeToolContext);
  const { profile, reload } = useContext(MembershipContext);
  const toolSettings = useToolSettings({ client });
  const { disable_pl_balance_sheet } = toolSettings.data;

  const {
    isOpen: isFinalizeOpen,
    setIsOpen: setIsFinalizeOpen,
    Modal: FinalizeModal,
    ModalHeader,
    ModalBody,
    ModalFooter
  } = useModal();
  const {
    isOpen: isResetOpen,
    setIsOpen: setIsResetOpen,
    Modal: ResetModal
  } = useModal();
  const saveAsModal = useModal();
  const settingsModal = useModal();
  const openModal = useModal();
  const [resetPending, setResetPending] = useState();

  const { selectedYear } = state;
  const data = state.years[state.selectedYear];
  const { totals } = data;
  const balanced = totals.in === totals.out;

  const dummyYearTabs = useMemo(
    () => {
      const { length } = state.years;
      const create = 10 - length;
      if (create <= 0) return null;
      const start = length + 1;
      return range(start, create + start).map(i => (
        <YearTab muted key={i} disabled year={i} />
      ));
    },
    [state.years.length]
  );

  const rotate = (() => {
    if (parseInt(totals.in) === parseInt(totals.out)) return '0';
    if (parseInt(totals.in) < parseInt(totals.out)) return '2';
    return '-2';
  })();

  function goBack() {
    history.goBack();
  }

  async function onReset() {
    setResetPending(true);

    try {
      await passedOnReset();
      setIsResetOpen(false);
    } finally {
      setResetPending(false);
    }
  }

  async function onSubmitSettings(x) {
    await client.post('tool_settings', x);
    await toolSettings.mutate();
  }

  if (toolSettings.isLoading) return null;

  return (
    <div className="practice-tool">
      {settingsModal.isOpen && (
        <Settings value={toolSettings.data} onSave={onSubmitSettings} {...settingsModal} />
      )}
      {saveAsModal.isOpen && (
        <SaveAs value={value} onSave={() => onSave(state)} onSaveNew={name => onSaveNew({ name, data: state })} {...saveAsModal} />
      )}
      {openModal.isOpen && (
        <Open {...openModal} onRemove={onRemove} client={client} onOpen={onOpen} />
      )}
      {isFinalizeOpen && (
        <FinalizeModal>
          <ModalHeader>Finalise Accounts</ModalHeader>
          <ModalBody>
            {totals.in === totals.out ? (
              <span>
                Your accounts are balanced and ready to be closed off for the
                year end. Click finalise accounts to close the accounts. This
                can't be undone.
              </span>
            ) : (
              <span>
                You accounts are not balanced and cannot be closed off for the
                year end.
              </span>
            )}
          </ModalBody>
          <ModalFooter className="justify-between">
            {totals.in === totals.out ? (
              <>
                <Button
                  color="link"
                  onClick={() => {
                    setIsFinalizeOpen(false);
                  }}
                >
                  CANCEL
                </Button>
                <Button
                  color="link"
                  className="text-secondary"
                  onClick={() => {
                    setIsFinalizeOpen(false);
                    if (data.status === 'EDITABLE') {
                      setStatus('REVIEW');
                    }
                    finalizeYear();
                    NotificationManager.success(`Year ${data.year} closed off`);
                  }}
                >
                  FINALISE ACCOUNTS
                </Button>
              </>
            ) : (
              <>
                <div className="mr-auto" />
                <Button color="link" onClick={() => setIsFinalizeOpen(false)}>
                  CLOSE
                </Button>
              </>
            )}
          </ModalFooter>
        </FinalizeModal>
      )}
      {isResetOpen && (
        <ResetModal>
          <ModalHeader>Reset Tool</ModalHeader>
          <ModalBody>
            Are you sure you want to reset the tool back to the default state?
          </ModalBody>
          <ModalFooter className="justify-between">
            <Button color="link" onClick={() => setIsResetOpen(false)}>
              CANCEL
            </Button>
            <PendingButton
              color="link"
              className="text-secondary"
              pending={resetPending}
              onClick={onReset}
            >
              CONFIRM
            </PendingButton>
          </ModalFooter>
        </ResetModal>
      )}
      <div className="flex -mt-2 mb-1 print:hidden">
        <Button color="link" className="font-medium" onClick={goBack}>
          <span className="fa fa-chevron-left mr-2" /> BACK{' '}
        </Button>
        <div className="mx-auto" style={{ paddingTop: '.375rem', paddingBottom: '.375rem' }}>
          {value.name !== 'default' ?  <><strong>Viewing:</strong> {value.name}</>: ''}
        </div>
        <Button
          color="link"
          className="font-medium"
          onClick={() => setIsResetOpen(true)}
        >
          RESET
        </Button>
        <Button
          color="link"
          className="font-medium"
          onClick={undo}
          disabled={!get(state, 'undo.length')}
        >
          UNDO
        </Button>
        <Button
          color="link"
          className="font-medium"
          onClick={redo}
          disabled={!get(state, 'redo.length')}
        >
          REDO
        </Button>
        <Button
          color="link"
          className="font-medium"
          onClick={() => {
            window.print();
          }}
        >
          PRINT
        </Button>
        <Button
          color="link"
          className="font-medium"
          onClick={() => openModal.setIsOpen(true)}
        >
          OPEN
        </Button>
        <Button
          color="link"
          className="font-medium"
          disabled={data.status === 'REVIEW'}
          onClick={() => onSave(state)}
        >
          SAVE
        </Button>
        <Button
          color="link"
          className="font-medium"
          disabled={data.status === 'REVIEW'}
          onClick={() => saveAsModal.setIsOpen(true)}
        >
          SAVE AS
        </Button>
        <Button
          color="link"
          className="font-medium"
          onClick={() => settingsModal.setIsOpen(true)}
        >
          SETTINGS
        </Button>
      </div>
      <ul className="nav nav-tabs">
        {state.years.map((i, idx) => (
          <YearTab
            key={i.year}
            year={i.year}
            onClick={() => setSelectedYear(idx)}
            active={selectedYear === idx}
            disabled={data.status === 'REVIEW'}
          />
        ))}
        {dummyYearTabs}
      </ul>
      <div className="flex">
        <div>
          <div>
            <div className="flex">
              <div
                style={{ writingMode: 'vertical-lr' }}
                className="w-12 pt-32 text-grey"
              >
                Profit and Loss <RotatedDownIcon />
              </div>
              <div className="flex">
                <div
                  className="border-0 border-r border-black border-solid pb-4"
                  style={quadrantContainerStyle}
                >
                  <div className="mr-4 pt-4 h-full flex flex-col">
                    <div className="h-8 text-center text-grey">
                      Accounts with period end Debit (DR) Balances <DownIcon />
                    </div>
                    <Quadrant type="EXPENSES" />
                  </div>
                </div>
                <div className="pb-4" style={quadrantContainerStyle}>
                  <div className="mx-4 pt-4 h-full flex flex-col">
                    <div className="h-8 text-center text-grey">
                      Accounts with period end Credit (CR) Balances <DownIcon />
                    </div>
                    <Quadrant type="REVENUE" />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex">
              <div
                style={{ writingMode: 'vertical-lr' }}
                className="w-12 pt-24 text-grey"
              >
                Balance Sheet <RotatedDownIcon />
              </div>
              <div className="flex">
                <div
                  className="border-0 border-r border-t border-black border-solid py-4"
                  style={lowerQuadrantContainerStyle}
                >
                  <div className="mr-4 h-full flex flex-col">
                    <Quadrant type="ASSETS" />
                  </div>
                </div>
                <div
                  className="py-4 border-0 border-t border-black border-solid"
                  style={lowerQuadrantContainerStyle}
                >
                  <div className="mx-4 h-full flex flex-col">
                    <Quadrant type="LIABILITIES/EQUITY" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="px-32 mt-6"
            style={{ transform: `rotate(${rotate}deg)` }}
          >
            <div className="flex justify-between mb-2">
              <div className="inline-block w-48 ml-12">
                <div className="flex mb-1">
                  <div className="mr-1 text-center border-2 border-solid border-steelblue flex-grow  py-1 px-2 relative">
                    <div className="absolute pin bg-steelblue print:bg-invisible  opacity-25 z-0" />
                    €{formatNumber(totals.expenses)}
                  </div>
                  <div className="border-2 text-center border-solid border-teal-2 flex-grow  py-1 px-2 relative">
                    <div className="absolute pin bg-teal-2 print:bg-invisible opacity-25 z-0" />
                    €{formatNumber(totals.assets)}
                  </div>
                </div>
                <div className="border text-center py-1 px-2 border-solid">
                  €{formatNumber(totals.in)}
                </div>
              </div>
              <span
                className={classNames(
                  'self-end font-medium text-xl',
                  !balanced ? 'text-red' : 'opacity-75'
                )}
              >
                {balanced ? 'BALANCED' : 'NOT BALANCED'}
              </span>
              <div className="inline-block w-48 mr-12">
                <div className="flex mb-1">
                  <div className="mr-1 text-center border-2 border-solid border-firebrick flex-grow py-1 px-2 relative">
                    <div className="absolute pin bg-firebrick print:bg-invisible opacity-25 z-0" />
                    €{formatNumber(totals.revenue)}
                  </div>
                  <div className="border-2 text-center border-solid border-goldenrod flex-grow  py-1 px-2 relative">
                    <div className="absolute pin bg-goldenrod print:bg-invisible opacity-25 z-0" />
                    €{formatNumber(totals.equity)}
                  </div>
                </div>
                <div className="border text-center py-1 px-2 border-solid">
                  €{formatNumber(totals.out)}
                </div>
              </div>
            </div>
            <div className="border-0 border-t-2 border-solid" />
            <div className="flex justify-center">
              <svg width="50" height="50">
                <ellipse ry="25" rx="25" cy="25" cx="25" fill="#809ea8" />
              </svg>
            </div>
          </div>
        </div>
        <div
          className="border-0 border-grey border-solid text-sm border-l"
          style={{ minWidth: '20rem', maxWidth: '20rem' }}
        >
          {!disable_pl_balance_sheet ? (
            <div>
              <div style={{ height: '21rem' }} className="pl-3 flex flex-col">
                <div className="mb-1 pt-4">Profit & Loss for Yr {data.year}</div>
                <table className="table table-sm bg-snow table-bordered text-xs profit-loss-table flex-grow text-grey-darker">
                  <tbody>
                  <tr>
                    <td className="text-center w-full">A/C Name</td>
                    <td className="text-center" style={euroCellStyle}>
                      €
                    </td>
                  </tr>
                  <tr className={styles.revenue}>
                    <td className="font-medium">
                      Revenue
                    </td>
                    <td />
                  </tr>
                  <ProfitLossRows
                    value={get(data, 'profit_loss.revenue_accounts')}
                  />
                  <tr className={styles.expenses}>
                    <td className="font-medium">(less) Expenses</td>
                    <td />
                  </tr>
                  <ProfitLossRows
                    value={get(data, 'profit_loss.expense_accounts')}
                  />
                  <tr>
                    <td className="font-medium">Profit/(Loss) for Year</td>
                    <td className="text-right">
                      {formatNumber(get(data, 'profit_loss.total'), true)}
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div className="border-0 border-t border-black border-solid pl-3 pt-4">
                <div className="mb-1">
                  Balance Sheet as at 31 December Yr {data.year}
                </div>
                <table className="table table-sm bg-snow table-bordered text-xs balance-sheet-table text-grey-darker">
                  <tbody>
                  <tr className={styles.assets}>
                    <td className="font-medium w-full">Assets</td>
                    <td className="text-center" style={euroCellStyle}>
                      €
                    </td>
                  </tr>
                  <BalanceSheetRows
                    value={get(data, 'balance_sheet.asset_accounts')}
                  />
                  <tr>
                    <td className="font-medium">Total</td>
                    <td className="text-right">
                      {formatNumber(get(data, 'balance_sheet.assets_total'))}
                    </td>
                  </tr>
                  </tbody>
                </table>
                <table className="table table-sm bg-snow table-bordered text-xs balance-sheet-table text-grey-darker mb-0">
                  <tbody>
                  <tr className={styles.liabilities}>
                    <td className="font-medium w-full">Liabilities/Equity</td>
                    <td className="text-center" style={euroCellStyle}>
                      €
                    </td>
                  </tr>
                  <BalanceSheetRows
                    value={get(data, 'balance_sheet.equity_accounts')}
                  />
                  <tr>
                    <td className="font-medium">Total</td>
                    <td className="text-right">
                      {formatNumber(get(data, 'balance_sheet.equity_total'))}
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          ) : null}
          <div className="text-center mb-3 mt-4">
            Status:{' '}
            <strong className="text-lg text-grey-darker">
              {
                {
                  EDITING: 'EDITABLE',
                  FINAL: 'FINALISE',
                  REVIEW: 'REVIEW'
                }[data.status]
              }
            </strong>
          </div>
          <div className="flex pl-3">
            <Button
              className={classNames("flex-1 rounded-lg print:bg-invisible", data.status !== 'FINAL' ? 'btn-invert-disabled' : '')}
              color="success"
              onClick={() => setStatus('EDITING')}
              disabled={data.status !== 'REVIEW'}
            >
              EDITABLE
            </Button>
            <div className="ml-1" />
            <Button
              className={classNames("flex-1 rounded-lg print:bg-invisible", data.status !== 'FINAL' ? 'btn-invert-disabled' : '')}
              color="orange-light"
              onClick={() => setStatus('REVIEW')}
              disabled={data.status !== 'EDITING'}
            >
              REVIEW
            </Button>
            <div className="ml-1" />
            <Button
              className={classNames("flex-1 rounded-lg print:bg-invisible", data.status !== 'FINAL' ? 'btn-invert-disabled' : '')}
              color="red"
              disabled={data.status === 'FINAL'}
              onClick={() => setIsFinalizeOpen(true)}
            >
              FINALISE
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ({ client, ...props }) => {
  const [toolState, setToolState] = useState();
  const [key, setKey] = useState(() => shortid());
  const { profile, reload } = useMembership();

  async function onInit() {
    const id = profile && profile.loaded_tool_state ? profile.loaded_tool_state : 'default';
    const toolState = await client.get(`tool_state/${id}`).get('data');
    setToolState(toolState);
  }

  async function onReset() {
    await client.post(`tool_state/${toolState.id}/reset`).get('data');
    await onInit();
    setKey(shortid());
  }

  async function onSave({ undo, redo, ...data }) {
    try {
      await client.put(`tool_state/${toolState.id}`, {
        ...toolState,
        data,
      });
      NotificationManager.success('Saved successfully');
    } catch (ex) {
      NotificationManager.error('Unable to save at this time');
      throw ex;
    }
  }

  async function onSaveNew({ name, data: { undo, redo, ...data } }) {
    try {
      const toolState = await client.post('tool_state', {
        name,
        data
      }).get('data');
      await client.post(`tool_state/${toolState.id}/set_loaded`);
      await reload({ silent: true });
      NotificationManager.success('Saved successfully');
      setToolState(toolState);
      setKey(shortid());
    } catch (ex) {
      NotificationManager.error('Unable to save at this time');
      throw ex;
    }
  }

  async function onOpen(toolState) {
    setToolState(toolState);
    await client.post(`tool_state/${toolState.id}/set_loaded`);
    setKey(shortid());
  }

  async function onRemove(value) {
    await client.delete(`tool_state/${value.id}`);
    if (value.id === toolState.id) {
      await reload();
    }
  }

  useEffect(() => void onInit(), [profile && profile.loaded_tool_state]);

  if (!toolState) return null;

  return (
    <PracticeToolProvider key={key} initialState={toolState.data}>
      <PracticeTool onRemove={onRemove} onReset={onReset} onOpen={onOpen} onSaveNew={onSaveNew} onSave={onSave} value={toolState} client={client} {...props} />
    </PracticeToolProvider>
  );
};
