import React from 'react';
import { useForm, usePageHelpers } from 'hooks';
import { FormGroup, SubmitButton } from 'components/common';
import { Errors } from 'react-redux-form';
import { Warning, Success } from 'components/common';
import { every, chain } from 'lodash';
import './style-invite.scss';
import { isEmail } from 'validator';

export const Invite = ({ client }) => {
  const { Form, Control, dispatch, actions } = useForm({
    initialState: {
      emails: ''
    }
  });
  const { PageTitle, PageCard } = usePageHelpers();

  async function onSubmit({ emails }) {
    try {
      dispatch(actions.resetErrors('info'));
      const failed = await client
        .post('/invite', {
          emails: chain(emails.split('\n'))
            .map(i => i.trim())
            .uniq()
            .compact()
            .value()
        })
        .get('data');

      if (failed.length && failed.length > 0) {
        dispatch(
          actions.setErrors(
            'info.warning',
            `Failed to send invitations to the following emails: ${failed.join(
              ','
            )}`
          )
        );
      } else {
        dispatch(
          actions.setErrors(
            'info.success',
            'An invite has been sent to each of the email addresses.'
          )
        );
      }
    } finally {
      dispatch(actions.setPending('local', false));
    }
  }

  return (
    <div className="max-w-sm mx-auto">
      <PageTitle>Invite</PageTitle>
      <PageCard className="p-3">
        <Form
          onSubmit={onSubmit}
          validators={{
            emails: v => every(v.split('\n'), i => isEmail(i))
          }}
        >
          <FormGroup label="Invite the following emails:" model=".emails">
            <Control.textarea
              debounce={100}
              className="form-control"
              rows={10}
              model=".emails"
            />
            <Errors model="info.warning" component={Warning} />
            <Errors model="info.success" component={Success} />
            <p className="form-text text-muted">One email address per line</p>
          </FormGroup>
          <div className="flex justify-end">
            <SubmitButton>Invite</SubmitButton>
          </div>
        </Form>
      </PageCard>
    </div>
  );
};

export default Invite;
