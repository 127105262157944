import React, { useCallback, useState } from 'react';
import {
  Page,
  FormGroup,
  SubmitButton,
  required,
  Warning
} from 'components/common';
import { useForm } from 'hooks';
import { Errors } from 'react-redux-form';
import { Link } from 'react-router-dom';
import { get } from 'lodash';

const PasswordReset = ({ client, match }) => {
  const { Form, Control, dispatch, actions } = useForm();
  const Container = useCallback(
    props => <Page title="Reset Your Password" {...props} />,
    []
  );
  const passwordsMatch = ({ password, confirm_new_password }) => {
    if (!(password && confirm_new_password)) return true;
    return password === confirm_new_password;
  };

  const [success, setSuccess] = useState(false);

  async function onSubmit(x) {
    try {
      dispatch(actions.resetErrors('info'));
      await client.post('/password_reset', {
        password: x.password,
        token: match.params.token
      });
      setSuccess(true);
    } catch (ex) {
      switch (get(ex, 'response.data.code')) {
        case 'PasswordFailsValidation':
          dispatch(
            actions.setErrors(
              'info.password',
              'Password must be at least 8 characters and be a mix of letters and numbers'
            )
          );
          break;
        case 'InvalidToken':
          dispatch(
            actions.setErrors(
              'info.invalidToken',
              'This token has already been used to change the password'
            )
          );
          break;
        case 'TokenExpired':
          dispatch(
            actions.setErrors(
              'info.expiredToken',
              'The password change token has expired'
            )
          );
          break;
        default:
          throw ex;
      }
    } finally {
      dispatch(actions.setPending('local', false));
    }
  }

  const formHtml = (
    <Container title="Set a new password">
      <Form
        validators={{
          '': { passwordsMatch },
          password: required,
          confirm_new_password: required
        }}
        onSubmit={onSubmit}
      >
        <FormGroup label="New Password *" model=".password">
          <Control.text
            type="password"
            model=".password"
            className="form-control"
            updateOn="blur"
          />
        </FormGroup>
        <FormGroup label="Confirm Password *" model=".confirm_new_password">
          <Control.text
            type="password"
            model=".confirm_new_password"
            className="form-control"
            updateOn="blur"
          />

          <Errors model="info.password" component={Warning} />
          <Errors model="info.invalidToken" component={Warning} />
          <Errors model="info.expiredToken" component={Warning} />
          <Errors
            model="local"
            messages={{ passwordsMatch: 'Passwords do not match' }}
            component={Warning}
          />
        </FormGroup>

        <div className="flex justify-end">
          <SubmitButton>Reset Password</SubmitButton>
        </div>
      </Form>
    </Container>
  );

  const successHtml = (
    <Container title="Success!">
      {
        <p>
          Your password has been changed. You may now{' '}
          <b>
            <u>
              <Link to="/login" replace>
                log in
              </Link>
            </u>
          </b>{' '}
          using it
        </p>
      }
    </Container>
  );

  return success ? successHtml : formHtml;
};

export default PasswordReset;
