import React from 'react';
import { createSelector } from 'reselect';
import produce from 'immer';
import moment from 'moment';
import numberToWords from 'number-to-words';
import { capitalize, sumBy, chain } from 'lodash';

const timeFormat = v =>
  moment.duration(v, 'minutes').format('h [hours] m [minutes]');

const sectionEstimate = (sections, type) => {
  const sectionsByType = chain(sections)
    .flatMap(i => i.content)
    .filter({ type })
    .value();

  const estimatedTime = sumBy(sectionsByType, j =>
    j.completed ? 0 : j.estimated_time
  );

  return sectionsByType.length
    ? {
        completed: estimatedTime === 0,
        estimated_time: timeFormat(estimatedTime)
      }
    : null;
};

export default createSelector(
  v => v,
  produce(draft => {
    draft.modules.map((i, idx) => {
      i.total_estimated_time = timeFormat(i.total_estimated_time);
      i.module_name = capitalize(numberToWords.toWords(idx + 1));

      i.videos = sectionEstimate(i.sections, 'Video');
      i.readings = sectionEstimate(i.sections, 'Reading');
      i.practicals = sectionEstimate(i.sections, 'Practical');
      i.state = ' - not started';
      if (i.completed) i.state = ' - completed';
      else if (i.locked)
        i.state = (
          <>
            {' '}
            <span className="fa fa-lock" />
          </>
        );
      else if (i.started) i.state = <i> - in progress</i>;
      return i;
    });
  })
);
