import React, { useContext, useEffect, useState } from 'react';
import UserAvatar from 'react-user-avatar';
import { UserContext } from 'contexts';
import './style-dashboard.scss';
import { Link } from 'react-router-dom';

export const Dashboard = ({ client, match }) => {
  const { user } = useContext(UserContext);
  const [profile, setProfile] = useState();

  useEffect(
    () => {
      (async () => {
        const profile = await client.get('user_profile').get('data');
        setProfile(profile);
      })();
    },
    [user.id]
  );

  if (!profile) return null;

  return (
    <div className="container">
      <div className="flex main-flex-dashboard">
        <div className="card card-dashboard">
          <div className="card-body card-body-dashboard">
            <div className="flex">
              <UserAvatar
                size="96"
                name={profile.user__full_name || profile.user__email}
                src={profile.profile_image__data}
              />
              <h4 style={{ margin: '5px 15px 5px 15px' }}>
                Welcome
                <br />
                <b>{profile.user__first_name}</b>
              </h4>
            </div>
            <div
              className="flex"
              style={{
                flexWrap: 'wrap',
                flexDirection: 'column',
                marginTop: '50px'
              }}
            >
              <Link to="/account" className="text-secondary">
                View your account
              </Link>
              <Link to="/courses" className="text-secondary">
                Resume course
              </Link>
            </div>
          </div>
        </div>

        <div className="card accq-blue-main card-coloured card-coloured-dashboard">
          <div className="card-body card-body-dashboard flex text-2xl">
            <span className="fa fa-laptop icon-dashboard" />
            <p>ACCQ Course</p>
          </div>
          <Link
            to="/courses"
            className="card-footer card-footer-dashboard accq-blue-side flex"
          >
            <p className="mb-0">
              Learn the ACCQ method with our interactive course
            </p>
            <span className="fa fa-chevron-right" />
          </Link>
        </div>

        <div className="card accq-green-main card-coloured card-coloured-dashboard">
          <div className="card-body card-body-dashboard flex text-2xl">
            <span className="fa fa-download icon-dashboard" />
            <p>ACCQ Practice Tool</p>
          </div>
          <Link
            to="/accq_tool"
            className="card-footer card-footer-dashboard accq-green-side flex"
          >
            <p className="mb-0">Use our practice tool and activities</p>
            <span className="fa fa-chevron-right" />
          </Link>
        </div>

        <div className="card accq-yellow-main card-coloured card-coloured-dashboard">
          <div className="card-body card-body-dashboard flex text-2xl">
            <span className="fa fa-folder-open icon-dashboard" />
            <p>Resources</p>
          </div>
          <a
            href="https://www.bingbong.ie/resources/"
            className="card-footer card-footer-dashboard accq-yellow-side flex"
          >
            <p className="mb-0">Search resources and articles</p>
            <span className="fa fa-chevron-right" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
