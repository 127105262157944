// pre: static perfix
// format: regex to determine if the value entered is formatted correctly.
// ph: placeholder value to make it more intuitive

const vatFormats = {};
vatFormats['AT'] = {
  pre: 'ATU',
  format: '^\\d{9}$',
  ph: '99999999',
  label: ' (UID)'
};
vatFormats['BE'] = {
  pre: 'BE',
  format: '^(0|1)?\\d{9}$',
  ph: '0999999999',
  label: ' (n° TVA; BTW-nr)'
};
vatFormats['BG'] = {
  pre: 'BG',
  format: '^\\d{9,10}$',
  ph: '999999999',
  label: ' (ДДС номер)'
};
vatFormats['HR'] = {
  pre: 'HR',
  format: '^\\d{11}$',
  ph: '12345678901',
  label: ' (PDV-ID; OIB)'
};
vatFormats['CY'] = {
  pre: 'CY',
  format: '^[a-zA-Z0-9]{9}$',
  ph: '99999999L',
  label: ' (ΦΠΑ)'
};
vatFormats['CZ'] = {
  pre: 'CZ',
  format: '^\\d{8,10}$',
  ph: '99999999',
  label: ' (DIČ)'
};
vatFormats['DK'] = {
  pre: 'DK',
  format: '^\\d{8}$',
  ph: '99999999',
  label: ' (CVR)'
};
vatFormats['EE'] = {
  pre: 'EE',
  format: '^\\d{9}$',
  ph: '999999999',
  label: ' (KMKR)'
};
vatFormats['FI'] = {
  pre: 'FI',
  format: '^\\d{8}$',
  ph: '99999999',
  label: ' (ALV nro; Momsnummer)'
};
vatFormats['FR'] = {
  pre: 'FR',
  format: '^[a-zA-Z0-9]{2}\\d{9}$',
  ph: 'XX999999999',
  label: ' (n° TVA)'
};
vatFormats['DE'] = {
  pre: 'DE',
  format: '^\\d{9}$',
  ph: '999999999',
  label: ' (USt-IdNr.)'
};
vatFormats['GR'] = {
  pre: 'EL',
  format: '^\\d{9}$',
  ph: '999999999',
  label: ' (ΑΦΜ)'
};
vatFormats['HU'] = {
  pre: 'HU',
  format: '^\\d{8}$',
  ph: '99999999',
  label: ' (ANUM)'
};
vatFormats['IE'] = {
  pre: 'IE',
  format: '^\\d{7}[A-Za-z]{0,2}$',
  ph: '9999999X(W)',
  label: ' / CBL'
};
vatFormats['IT'] = {
  pre: 'IT',
  format: '^\\d{11}$',
  ph: '99999999999',
  label: ' (P.IVA)'
};
vatFormats['LV'] = {
  pre: 'LV',
  format: '^\\d{11}$',
  ph: '99999999999',
  label: ' (PVN)'
};
vatFormats['LT'] = {
  pre: 'LT',
  format: '(^\\d{9}$)|(^\\d{12}$)',
  ph: '999999999 / 999999999999',
  label: ' (PVM kodas)'
};
vatFormats['LU'] = {
  pre: 'LU',
  format: '^\\d{8}$',
  ph: '99999999',
  label: ' (No. TVA)'
};
vatFormats['MT'] = { pre: 'MT', format: '^\\d{8}$', ph: '99999999', label: '' };
vatFormats['NL'] = {
  pre: 'NL',
  format: '^\\d{9}B\\d{2}$',
  ph: '999999999B99',
  label: ' (Btw-nr.)'
};
vatFormats['PL'] = {
  pre: 'PL',
  format: '^\\d{10}$',
  ph: '9999999999',
  label: ' (NIP)'
};
vatFormats['PT'] = {
  pre: 'PT',
  format: '^\\d{9}$',
  ph: '999999999',
  label: ' (NIF / NIPC)'
};
vatFormats['RO'] = {
  pre: 'RO',
  format: '^\\d{2,10}$',
  ph: '99999999 / 9999999999',
  label: ' (CIF)'
};
vatFormats['SK'] = {
  pre: 'SK',
  format: '^\\d{10}$',
  ph: '9999999999',
  label: ' (IČ DPH)'
};
vatFormats['SI'] = {
  pre: 'SI',
  format: '^\\d{8}$',
  ph: '99999999',
  label: ' (ID za DDV)'
};
vatFormats['ES'] = {
  pre: 'ES',
  format: '^[a-zA-Z0-9]\\d{7}[a-zA-Z0-9]$',
  ph: 'X99999999',
  label: ' (NIF / CIF)'
};
vatFormats['SE'] = {
  pre: 'SE',
  format: '^\\d{12}$',
  ph: '999999999901',
  label: ' (Momsnr.)'
};
vatFormats['GB'] = {
  pre: 'GB',
  format: '(^\\d{9}$)|(^\\d{12}$)|(^GD\\d{3}$)',
  ph: '999999999 / 999999999999 / GD999',
  label: ''
};

// Useful bit of code to see if you messed up any regexes.
// Only checks if they break, not if the actual vat formatting is correct.
// TODO: find some testing framework and put this in
const testRegexValidity = vatFormats => {
  for (let countryId in vatFormats) {
    try {
      new RegExp(vatFormats[countryId].format).test('123abc.,!');
    } catch (error) {}
  }
};

export { vatFormats, testRegexValidity };
