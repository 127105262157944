import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { compose, withHandlers, withProps } from 'recompose';

export const EditDeleteButtons = ({
  editProps,
  deleteProps,
  deleteText,
  url,
  id,
  wrapOnDelete,
  allowDelete,
  readOnly
}) => {
  const editButton = (
    <Link
      className="btn btn-primary btn-sm mr-1"
      to={`${url}/${id}`}
      {...editProps}
    >
      <span className={readOnly ? 'fa fa-eye' : 'fa fa-edit'} />
    </Link>
  );
  const deleteButton = (
    <Button color="danger" size="sm" {...deleteProps} onClick={wrapOnDelete}>
      <span className="fa fa-trash" /> {deleteText}
    </Button>
  );

  return (
    <>
      {url && id ? editButton : null}
      {allowDelete ? deleteButton : null}
    </>
  );
};

const enhance = compose(
  withProps(({ onDelete }) => ({
    allowDelete: !!onDelete
  })),
  withHandlers({
    wrapOnDelete: ({ id, onDelete }) => () => onDelete(id)
  })
);

export default enhance(EditDeleteButtons);
