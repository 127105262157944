import React from 'react';
import classNames from 'classnames';

export const BooleanIcon = ({ test, className, ...props }) => (
  <div className="flex justify-center">
    <div
      className={classNames('inline-block fa fa-fw', {
        'text-danger fa-minus-circle': !test,
        'text-success fa-check-circle': !!test
      })}
    />
  </div>
);

export default BooleanIcon;
