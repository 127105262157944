import React from 'react';
import { FormGroup as ReactstrapFormGroup, Label } from 'reactstrap';
import classNames from 'classnames';
import { omit } from 'lodash';
import { connect } from 'react-redux';
import { getModel, getField } from 'react-redux-form';
import resolveModel from 'react-redux-form/lib/utils/resolve-model';
import { compose, branch, mapProps } from 'recompose';
import { Trans } from 'services/i18n';

export const FormGroup = ({
  label,
  check,
  inline,
  children,
  className,
  model,
  storeSubscription,
  disableUntouchedState,
  color,
  labelProps,
  htmlFor,
  ...props
}) => {
  return (
    <ReactstrapFormGroup
      color={color}
      className={classNames(className, { 'form-check-inline': !!inline })}
      check={check}
      {...props}
    >
      {check ? (
        <>
          {children}
          <Label check htmlFor={htmlFor}>
            {!!label && [' ', <Trans key="trans">{label}</Trans>]}
          </Label>
        </>
      ) : (
        [
          !!label && (
            <Label key="label" {...labelProps}>
              <Trans>{label}</Trans>
            </Label>
          ),
          children
        ]
      )}
    </ReactstrapFormGroup>
  );
};

const connectedFormGroup = compose(
  resolveModel,
  connect((state, { model, className, disableUntouchedState }) => {
    if (!model || !state.forms) return {};

    const modelString = getModel(
      typeof model === 'function' ? model(state) : model,
      state
    );
    const fieldState = getField(state.forms, modelString);

    if (!fieldState) return {};
    if (fieldState.touched && !fieldState.valid) {
      return { className: classNames(className, 'has-danger') };
    } else if (fieldState.touched && fieldState.valid) {
      return { className: classNames(className, 'has-success') };
    } else if (
      !fieldState.touched &&
      !fieldState.valid &&
      !disableUntouchedState
    ) {
      return { className: classNames(className, 'has-warning') };
    }
    return {};
  }),
  mapProps(props =>
    omit(props, ['dispatch', 'model', 'storeSubscription', 'store'])
  )
);

const enhance = compose(branch(props => props.model, connectedFormGroup));

export default enhance(FormGroup);
