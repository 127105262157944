import React, { useEffect, useState } from 'react';
import { Progress } from 'reactstrap';
import { Page } from 'components/common';
import { Link } from 'react-router-dom';

export const List = ({ client, match }) => {
  const [data, setData] = useState();

  useEffect(() => {
    (async () => {
      const data = await client.get('user_content/courses').get('data');
      setData(data);
    })();
  }, []);

  if (!data) return null;

  return data.map(i => (
    <Page key={i.id} className="md:w-1/2 bg-snow" title={i.title}>
      {i.image ? (
        <div className="relative -ml-5 -mr-5 mb-6" style={{ height: '17rem' }}>
          <img src={`/api/serve/${i.image}`} alt="" className="w-full h-full" />
        </div>
      ) : null}

      <div className="flex items-center justify-between">
        <div className="flex flex-col items-center">
          <div className="text-sm font-medium mb-1">
            {i.percent_complete}% Completed
          </div>
          <Progress
            value={i.percent_complete}
            className="w-48"
            color="lightseagreen"
            style={{ height: 5 }}
          />
        </div>
        <Link className="link" to={`${match.url}/${i.id}`}>
          {i.completed
            ? 'VIEW MATERIAL'
            : i.started
            ? 'RESUME COURSE'
            : 'START COURSE'}
        </Link>
      </div>
    </Page>
  ));
};

export default List;
