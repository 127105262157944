import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import qs from 'query-string';

export const AddNewButton = ({
  resource,
  className,
  match,
  to = '/new',
  query,
  ...props
}) => (
  <Link
    className={classNames('btn btn-success', className)}
    to={`${match.url}${to}${query ? '?' + qs.stringify(query) : ''}`}
    {...props}
  >
    <span className="fa fa-plus mr-1" /> Add New {resource}
  </Link>
);

export default AddNewButton;
