import React, { useEffect } from 'react';
import { useForm, usePageHelpers } from 'hooks';
import { FormGroup, SubmitButton, required } from 'components/common';
import Select from 'react-select';
import { vatFormats } from './VatFormats';
import { getData } from 'country-list';
import { NotificationManager } from 'react-notifications';

export const UpdateBillingAddress = ({ client, history }) => {
  const { PageTitle, PageCard } = usePageHelpers();
  const { Form, Control, dispatch, actions, value, setValue } = useForm();

  const countryOptions = getData().map(({ name, code }) => ({
    key: code,
    label: name,
    value: code
  }));

  const showVat = vatFormats[value.country];

  async function onSubmit(x) {
    try {
      dispatch(actions.resetErrors('info'));
      await client.post('membership/update_billing_address', x);
      NotificationManager.success(
        'Your billing address details have been updated.'
      );
      history.pushUp();
    } finally {
      dispatch(actions.setPending('local', false));
    }
  }

  useEffect(() => {
    (async () => {
      const profile = await client.get('membership').get('data');
      setValue(profile);
    })();
  }, []);

  return (
    <div>
      <PageTitle>Update Billing Address</PageTitle>
      <PageCard className="p-3">
        <Form
          validators={{
            address_line_1: required,
            city: required,
            postcode: required,
            country: required,
            vat_number: v => (showVat ? required(v) : true)
          }}
          onSubmit={onSubmit}
        >
          <FormGroup label="Billing Address" model=".address_line_1">
            <Control.text
              placeholder="Address Line 1"
              model=".address_line_1"
              className="form-control"
            />
          </FormGroup>
          <FormGroup label="" model=".address_line_2">
            <Control.text
              placeholder="Address Line 2"
              model=".address_line_2"
              className="form-control"
            />
          </FormGroup>
          <FormGroup label="" model=".city">
            <Control.text
              placeholder="City"
              model=".city"
              className="form-control"
            />
          </FormGroup>
          <FormGroup label="" model=".postcode">
            <Control.text
              placeholder="Postcode"
              model=".postcode"
              className="form-control"
            />
          </FormGroup>
          <FormGroup label="" model=".country">
            <Control.text
              component={Select}
              options={countryOptions}
              simpleValue
              model=".country"
              clearable={false}
              placeholder="Country"
              onChange={v => {
                if (!vatFormats[v]) {
                  dispatch(actions.change('local.vat_number', ''));
                }
              }}
            />
          </FormGroup>
          {showVat && (
            <FormGroup label="VAT Number *" model=".vat_number">
              <Control.text model=".vat_number" className="form-control" />
            </FormGroup>
          )}

          <div className="flex justify-end">
            <SubmitButton model="local" disableInvalid>
              Update
            </SubmitButton>
          </div>
        </Form>
      </PageCard>
    </div>
  );
};

export default UpdateBillingAddress;
