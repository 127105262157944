import axios from 'axios';
import { get, merge, set } from 'lodash';
import history from 'config/history';
import { getToken, clearToken } from './Auth';

export default ({
  bypassAuthCheck = false,
  redirectOnUnauthorized = true,
  baseURL = '/api',
  headers,
  ...props
} = {}) => {
  const token = getToken();
  headers = merge(
    {},
    token && !bypassAuthCheck ? { Authorization: `JWT ${token}` } : {},
    headers
  );
  const config = {
    baseURL,
    headers,
    ...props
  };

  const client = axios.create(config);
  if (!bypassAuthCheck && redirectOnUnauthorized) {
    client.interceptors.response.use(null, err => {
      if (get(err, 'response.status') === 401) {
        err.handled = true;
        clearToken();
        history.push('/login');
      }
      throw err;
    });
  }

  client.interceptors.request.use(config => {
    if (config.method === 'get') {
      set(config, 'params.buster', new Date().getTime());
    }
    return config;
  }, null);

  return client;
};
