import React from 'react';
import { Button } from 'reactstrap';
import { Trans } from 'services/i18n';

export const PendingButton = ({
  pending,
  disabled,
  component: Component = Button,
  children,
  ...props
}) => (
  <Component disabled={pending || disabled} {...props}>
    {!!pending && <span className="fa fa-fw fa-spin fa-spinner mr-1" />}
    <Trans>{children}</Trans>
  </Component>
);

export default PendingButton;
