import React, { useEffect, useContext, useMemo } from 'react';
import { useForm, usePageHelpers } from 'hooks';
import { get } from 'lodash';
import { UserContext, MembershipContext } from 'contexts';
import { Link } from 'react-router-dom';
import { Form } from 'react-redux-form';
import { Auth, ApiClient } from 'services';
import { NotificationManager } from 'react-notifications';
import { Warning, Success, Info } from 'components/common/Message';

export const Invite = ({ match }) => {
  const { uuid } = match.params;
  const {
    dispatch,
    value,
    actions,
    Form: ParentForm,
    SubmitButton,
    Errors,
    FormGroup,
    Control,
    validators: { required, isEmail }
  } = useForm({
    initialState: {
      invite: null,
      login: {
        email: '',
        password: ''
      },
      register: {
        email: '',
        password: '',
        confirm_password: '',
        first_name: '',
        last_name: ''
      }
    }
  });
  const { reload } = useContext(MembershipContext);
  const { PageCard } = usePageHelpers();
  const { isUser, user } = useContext(UserContext);
  const { invite } = value;
  const client = useMemo(() => ApiClient(), [user ? user.id : null]);

  function getError(ex) {
    return {
      InvalidInvite:
        'This link is not a valid invite, please contact the person who sent it.',
      InviteAlreadyAccepted: 'This invite has already been accepted.',
      InvalidInviteEmail: `This invite can only be used by user ${
        invite ? invite.recipient : ''
      }`,
      MembershipAlreadyActive:
        'You already have an active subscription, please cancel this before accepting.'
    }[get(ex, 'response.data.code')];
  }

  async function init() {
    dispatch(actions.resetErrors('server'));

    try {
      const invite = await client
        .post('end_user/retrieve_invite', { uuid })
        .get('data');
      dispatch(actions.change('local.invite', invite));
      dispatch(actions.change('local.login.email', invite.recipient));
      dispatch(actions.change('local.register.email', invite.recipient));
    } catch (ex) {
      const error = getError(ex);
      if (!error) throw ex;
      dispatch(actions.setErrors('server', error));
    }
  }

  async function onSignIn({ email, password }) {
    try {
      await Auth.login({ email, password });
    } catch (ex) {
      if (get(ex, 'response.status') === 400) {
        NotificationManager.error(
          'Unable to sign in with the provided credentials'
        );
      } else {
        throw ex;
      }
    } finally {
      dispatch(actions.setPending('local.login', false));
    }
  }

  async function onAccept() {
    dispatch(actions.resetErrors('server'));
    dispatch(actions.setPending('local', true));

    try {
      await client.post('end_user/link', { uuid }).get('data');
      dispatch(actions.change('local.invite.success', true));
    } catch (ex) {
      const error = getError(ex);
      if (!error) throw ex;
      dispatch(actions.setErrors('server', error));
      return;
    } finally {
      dispatch(actions.setPending('local', false));
    }

    reload();
  }

  async function onRegister(x) {
    dispatch(actions.setPending('local.register', true));
    dispatch(actions.resetErrors('server'));

    try {
      await client.post('/end_user/register', x);
    } catch (ex) {
      const code = get(ex, 'response.data.code');

      if (code === 'invalid') {
        const data = ex.response.data;
        dispatch(
          actions.setErrors('server.email', data.email, { async: true })
        );
        dispatch(
          actions.setErrors('server.password', data.password, { async: true })
        );
        return;
      } else {
        throw ex;
      }
    } finally {
      dispatch(actions.setPending('local.register', false));
    }

    await Auth.login(x);
  }

  useEffect(() => void init(), [uuid]);

  return (
    <ParentForm>
      <PageCard className="p-3 mx-auto" style={{ width: '40rem' }}>
        {!!(invite && invite.success) && (
          <Success>
            Your account has been successfully linked, you may access the ACCQ
            content.
          </Success>
        )}
        {!!(invite && !invite.success) && (
          <div>
            <div className="text-center">
              You have been invited to join <strong>{invite.full_name}</strong>
              's ACCQ plan.
            </div>
            {isUser ? (
              <div className="flex justify-end">
                <SubmitButton className="block mt-3" onClick={onAccept}>
                  Accept
                </SubmitButton>
              </div>
            ) : invite.existing_user ? (
              <Form
                model="local.login"
                onSubmit={onSignIn}
                component="div"
                validators={{
                  password: v => !!v,
                  email: v => !!v
                }}
              >
                <h5 className="card-title font-light mt-3">Sign In</h5>
                <FormGroup label="Email" model=".email">
                  <Control.text
                    className="form-control"
                    model=".email"
                    readOnly
                  />
                </FormGroup>
                <FormGroup label="Password" model=".password">
                  <Control.input
                    type="password"
                    className="form-control"
                    model=".password"
                  />
                </FormGroup>
                <div className="flex items-center">
                  <Link to="/password_reset_request" className="link mr-auto">
                    Forgot Password?
                  </Link>
                  <SubmitButton
                    onClick={() => {
                      dispatch(actions.submit('local.login'));
                    }}
                  >
                    <span className="fa fa-sign-in mr-2" />
                    Sign In
                  </SubmitButton>
                </div>
              </Form>
            ) : (
              <Form
                model="local.register"
                onSubmit={onRegister}
                component="div"
                validators={{
                  password: required,
                  email: isEmail,
                  first_name: required,
                  last_name: required,
                  '': {
                    passwordsMatch: ({ password, confirm_password }) => {
                      if (!password || !confirm_password) return true;
                      return password === confirm_password;
                    }
                  },
                  confirm_password: required
                }}
              >
                <h5 className="card-title font-light mt-3">Register</h5>
                <FormGroup label="Email" model=".email">
                  <Control.text
                    className="form-control"
                    model=".email"
                    readOnly
                  />
                </FormGroup>
                <FormGroup label="First Name" model=".first_name">
                  <Control.text className="form-control" model=".first_name" />
                </FormGroup>

                <FormGroup label="Last Name" model=".last_name">
                  <Control.text className="form-control" model=".last_name" />
                </FormGroup>
                <FormGroup label="Password" model=".password">
                  <Control.input
                    type="password"
                    className="form-control"
                    model=".password"
                  />
                </FormGroup>
                <FormGroup label="Confirm Password" model=".confirm_password">
                  <Control.input
                    type="password"
                    className="form-control"
                    model=".confirm_password"
                    updateOn="blur"
                  />
                  <Errors
                    model="local.register"
                    messages={{
                      passwordsMatch: 'Passwords do not match'
                    }}
                    component={Warning}
                  />
                  <Errors model="server.password" component={Warning} />
                </FormGroup>
                <div className="flex justify-end">
                  <SubmitButton
                    onClick={() => {
                      dispatch(actions.submit('local.register'));
                    }}
                  >
                    <span className="fa fa-sign-in mr-2" />
                    Register
                  </SubmitButton>
                </div>
              </Form>
            )}
          </div>
        )}
        <div className="mt-3">
          <Errors model="server" component={Info} />
        </div>
      </PageCard>
    </ParentForm>
  );
};

export default Invite;
