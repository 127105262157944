import React, { useEffect } from 'react';
import {
  Page,
  FormGroup,
  SubmitButton,
  required,
  Warning
} from 'components/common';
import { useForm } from 'hooks';
import Dropzone from 'react-dropzone';
import UserAvatar from 'react-user-avatar';
import { Button } from 'reactstrap';
import { NotificationManager } from 'react-notifications';
import { Errors } from 'react-redux-form';
import { get } from 'lodash';

export const Profile = ({ client, history }) => {
  const {
    Form,
    Control,
    dispatch,
    actions,
    loaded,
    setValue,
    value
  } = useForm();

  useEffect(() => {
    (async () => {
      const profile = await client.get('user_profile').get('data');
      setValue({
        first_name: profile.user__first_name,
        last_name: profile.user__last_name,
        profile_image: profile.profile_image__data,
        contact_number: profile.contact_number
      });
    })();
  }, []);

  function onDropAccepted([file]) {
    if (file.size > 256 * 1000) {
      NotificationManager.error(
        'Profile images should be a maximum of 256kb in size'
      );
      return;
    }
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      dispatch(actions.change('local.profile_image', reader.result));
    });
    reader.readAsDataURL(file);
  }

  function onProfileImageError() {
    onClearProfileImage();
    NotificationManager.error('The uploaded file in not a valid image');
  }

  function onClearProfileImage() {
    dispatch(actions.change('local.profile_image', null));
  }

  async function onSubmit(x) {
    try {
      dispatch(actions.resetErrors('info'));
      await client.post('user_profile', {
        ...x,
        password_new: x.password_new || undefined,
        password_old: x.password_old || undefined
      });
      NotificationManager.success('Profile successfully saved');
      history.pushUp();
    } catch (ex) {
      dispatch(actions.setErrors('info.errors', get(ex, 'response.data.code')));
    } finally {
      dispatch(actions.setPending('local', false));
    }
  }

  const passwordsMatch = ({ password_old, password_new, password_confirm }) => {
    if (
      (password_old || password_new || password_confirm) &&
      !(password_old && password_new && password_confirm)
    )
      dispatch(
        actions.setErrors(
          'info.frontend',
          'If you would like to change your password, please fill all the fields. Otherwise, please leave them all empty'
        )
      );
    else if (
      !(password_new && password_confirm)
        ? false
        : password_new !== password_confirm
    )
      dispatch(actions.setErrors('info.frontend', 'Passwords do not match'));
    else dispatch(actions.setErrors('info.frontend', ''));
    return true;
  };

  if (!loaded) return null;

  return (
    <Page title="Profile Details">
      <Form
        validators={{
          first_name: required,
          last_name: required,
          '': { passwordsMatch }
        }}
        onSubmit={onSubmit}
      >
        <FormGroup label="Profile Image" model=".profile_image">
          <Dropzone
            style={{}}
            className="border rounded p-2 text-sm"
            onDropAccepted={onDropAccepted}
            multiple={false}
          >
            {value.profile_image ? (
              <>
                <UserAvatar name="#" src={value.profile_image} size="96" />
                <img
                  src={value.profile_image}
                  alt="Profile"
                  className="hidden"
                  onError={onProfileImageError}
                />
              </>
            ) : (
              'Click here select a profile image'
            )}
          </Dropzone>
          {value.profile_image ? (
            <Button size="sm" className="mt-2" onClick={onClearProfileImage}>
              Clear image
            </Button>
          ) : null}
        </FormGroup>
        <FormGroup label="First Name *" model=".first_name">
          <Control.text model=".first_name" className="form-control" />
        </FormGroup>
        <FormGroup label="Last Name *" model=".last_name">
          <Control.text model=".last_name" className="form-control" />
        </FormGroup>
        <FormGroup label="Contact Number" model=".contact_number">
          <Control.text model=".contact_number" className="form-control" />
        </FormGroup>

        <FormGroup label="Change Your Password" model=".password_old">
          <Control.text
            type="password"
            model=".password_old"
            className="form-control"
            placeholder="Current password"
          />
        </FormGroup>
        <FormGroup label="" model=".password_new">
          <Control.text
            type="password"
            model=".password_new"
            className="form-control"
            updateOn="blur"
            placeholder="New password"
          />
        </FormGroup>
        <FormGroup label="" model=".password_confirm">
          <Control.text
            type="password"
            model=".password_confirm"
            className="form-control"
            updateOn="blur"
            placeholder="Confirm new password"
          />
          <Errors model="info.frontend" component={Warning} />
          <Errors model="info.errors" component={Warning} />
        </FormGroup>

        <div className="flex justify-end">
          <SubmitButton>Save</SubmitButton>
        </div>
      </Form>
    </Page>
  );
};

export default Profile;
