import React from 'react';
import { keys } from 'lodash';
import { Button } from 'reactstrap';
import DeleteModal from './DeleteModal';
import { Trans } from 'services/i18n';

export const BulkDeleteButton = ({
  toggleModal,
  selected,
  url,
  onDeleted,
  isModalOpen,
  buttonProps,
  modalProps,
  readOnly,
  client,
  ...props
}) => {
  const numSelected = keys(selected).length;

  if (readOnly) return null;

  return (
    <div {...props}>
      <Button
        onClick={toggleModal}
        disabled={!numSelected}
        color="danger"
        {...buttonProps}
      >
        <span className="fa fa-trash" /> <Trans>Remove selected</Trans>{' '}
        {!!numSelected && <span>({numSelected})</span>}
      </Button>
      <DeleteModal
        url={url}
        client={client}
        onDeleted={onDeleted}
        isOpen={isModalOpen}
        toggle={toggleModal}
        selected={selected}
      />
    </div>
  );
};

export default BulkDeleteButton;
