import React, { useEffect } from 'react';
import { useForm } from 'hooks';
import { Page, required, FormGroup, SubmitButton } from 'components/common';
import { NotificationManager } from 'react-notifications';
import { isInt } from 'validator';
import { List as ModuleList } from './Modules';
import Dropzone from 'react-dropzone';

export const Edit = ({
  match,
  client,
  url = 'courses',
  title = 'Course',
  history
}) => {
  const {
    getTitle,
    loaded,
    load,
    setValue,
    Form,
    Control,
    save,
    dispatch,
    actions,
    value
  } = useForm();
  const { id } = match.params;
  const defaultItem = {
    title: '',
    published: false
  };

  async function onMount(id) {
    let item = await load(client, url, id, defaultItem);
    if (!item.id) {
      const order = await client.get(`${url}/next_order`).get('data');
      item = {
        ...item,
        ...order
      };
    }
    setValue(item);
  }

  async function onSubmit(x) {
    const { id } = await save(client, url, x).get('data');
    history.replace(match.path.replace(':id', id));
    onMount(id);
    NotificationManager.success('Saved successfully');
  }

  function onDropAccepted([file]) {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      dispatch(
        actions.change('local.image', {
          name: file.name,
          content: reader.result
        })
      );
    });
    reader.readAsDataURL(file);
  }

  useEffect(() => void onMount(id), [id]);

  if (!loaded) return null;

  return (
    <>
      <Page title={getTitle(id, title)}>
        <Form
          onSubmit={onSubmit}
          validators={{
            title: required,
            order: v => isInt(`${v}`, { min: 0 })
          }}
        >
          <FormGroup label="Title *" model=".title">
            <Control.text model=".title" className="form-control" />
          </FormGroup>
          <FormGroup label="Course Image *" model=".image">
            <Dropzone
              style={{}}
              className="border rounded p-2 text-sm"
              onDropAccepted={onDropAccepted}
              multiple={false}
            >
              {value.image
                ? `${value.image.name} selected`
                : 'Click here to select an image'}
            </Dropzone>
            {value.image__name ? (
              <img
                src={`/api/serve/${value.image__name}`}
                alt=""
                className="img-thumbnail mw-md"
                style={{ maxHeight: '10rem' }}
              />
            ) : null}
          </FormGroup>
          <FormGroup label="Order *" model=".order">
            <Control.text model=".order" className="form-control" />
          </FormGroup>
          <FormGroup>
            <FormGroup check label="Published?" model=".published">
              <Control.checkbox
                model=".published"
                className="form-check-input"
              />
            </FormGroup>
          </FormGroup>
          <FormGroup label="Overview" model=".overview">
            <Control.textarea
              rows="5"
              model=".overview"
              className="form-control"
            />
          </FormGroup>
          <div className="flex justify-end">
            <SubmitButton>Save</SubmitButton>
          </div>
        </Form>
      </Page>
      {!!value.id && (
        <ModuleList course={value.id} match={{ url: `${match.url}/modules` }} />
      )}
    </>
  );
};

export default Edit;
