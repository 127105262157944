import React from 'react';

export const PublishedCount = ({
  total,
  published,
  component: Component = 'span'
}) => {
  const unpublished = total - published;

  return (
    <Component>
      {published}
      {!!unpublished && <span className="opacity-75"> ({unpublished})</span>}
    </Component>
  );
};

export default PublishedCount;
