import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { usePageHelpers } from 'hooks';
import courseSelector from './courseSelector';

const ModuleSection = ({ type, locked, value, to }) => (
  <div className="flex">
    <div className="text-right w-48 mr-12">{type}</div>
    <div className="w-12 mr-12">
      {locked ? (
        <span className="fa fa-fw fa-lock" />
      ) : (
        <Link className="text-dodgerblue" to={to}>
          VIEW
        </Link>
      )}
    </div>
    <div className="w-32">
      {value.completed ? (
        <span className="fa fa-check text-success" />
      ) : (
        <span>{value.estimated_time} left</span>
      )}
    </div>
  </div>
);

export const View = ({ client, match }) => {
  const [data, setData] = useState();
  const { id } = match.params;
  const { PageTitle, PageCard } = usePageHelpers();

  useEffect(
    () => {
      (async () => {
        setData(null);
        const data = await client.get(`user_content/courses/${id}`).get('data');
        setData(courseSelector(data));
      })();
    },
    [id]
  );

  if (!data) return null;

  return (
    <div>
      <PageTitle>{data.title}</PageTitle>
      {data.modules.map((i, idx) => (
        <PageCard key={i.id} className="mb-5 bg-snow">
          <div>
            <div className="bg-maroon text-white rounded-t-minus-1px p-2 pl-5 flex">
              <div className="mr-auto">
                Module {i.module_name}
                {i.state}
              </div>
              <div className="font-light">
                Estimated time: {i.total_estimated_time}
              </div>
            </div>

            <div className="pl-5 pr-12 py-3">
              <div className="text-lg font-medium mb-2">{i.title}</div>
              {!!i.overview && <div className="font-medium">Overview</div>}
              <div className="flex justify-between">
                <div>{i.overview ? <div>{i.overview}</div> : null}</div>
                <div>
                  {i.videos && (
                    <ModuleSection
                      type="Videos"
                      locked={i.locked}
                      value={i.videos}
                      to={`${match.url}/modules/${i.id}`}
                    />
                  )}
                  {i.readings && (
                    <ModuleSection
                      type="Readings"
                      value={i.readings}
                      locked={i.locked}
                      to={`${match.url}/modules/${i.id}`}
                    />
                  )}
                  {i.practicals && (
                    <ModuleSection
                      type="Practice Exercises"
                      value={i.practicals}
                      locked={i.locked}
                      to={`${match.url}/modules/${i.id}`}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </PageCard>
      ))}
    </div>
  );
};

export default View;
