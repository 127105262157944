import React, { useEffect, useState } from 'react';
import { useForm } from 'hooks';
import {
  Page,
  required,
  FormGroup,
  SubmitButton,
  Warning
} from 'components/common';
import { Button } from 'reactstrap';
import { get } from 'lodash';
import { NotificationManager } from 'react-notifications';

export const Edit = ({ match, client, url = 'users', history }) => {
  const {
    getTitle,
    loaded,
    load,
    setValue,
    Form,
    Control,
    dispatch,
    actions,
    value,
    save,
    validators: { isInteger },
    Errors
  } = useForm();
  const [settingPassword, setSettingPassword] = useState(false);
  const { id } = match.params;

  async function onMount() {
    setSettingPassword(false);
    const item = await load(client, url, id, {
      email: '',
      first_name: '',
      last_name: '',
      password: '',
      is_active: true,
      profile: {
        no_membership_required: false,
        no_membership_num_users: 1
      }
    });
    setValue(item);
  }

  async function onSubmit(x) {
    dispatch(actions.resetErrors('server'));

    try {
      const { id } = await save(client, url, x).get('data');
      history.replace(match.path.replace(':id', id));
      NotificationManager.success('Saved successfully');
    } catch (ex) {
      const code = get(ex, 'response.data.code');

      if (code === 'invalid') {
        const data = ex.response.data;
        dispatch(actions.setErrors('server.email', data.email));
        dispatch(actions.setErrors('server.password', data.password));
      } else {
        throw ex;
      }
    }
  }

  function onCancelPassword() {
    setSettingPassword(false);
    dispatch(actions.reset('local.password'));
  }

  function onChangePassword() {
    setSettingPassword(true);
    dispatch(actions.change('local.password', ''));
  }

  useEffect(() => void onMount(), [id]);

  if (!loaded) return null;

  const passwordError = <Errors model="server.password" component={Warning} />;

  return (
    <Page title={getTitle(id, 'User')}>
      <Form
        onSubmit={onSubmit}
        validators={{
          first_name: required,
          last_name: required,
          email: required,
          'profile.no_membership_num_users': v => isInteger(v),
          password: v => (id === 'new' || settingPassword ? required(v) : true)
        }}
      >
        <FormGroup label="Email *" model=".email">
          <Control.text model=".email" className="form-control" />
          <Errors model="server.email" component={Warning} />
        </FormGroup>
        <FormGroup label="First Name *" model=".first_name">
          <Control.text model=".first_name" className="form-control" />
        </FormGroup>
        <FormGroup label="Last Name *" model=".last_name">
          <Control.text model=".last_name" className="form-control" />
        </FormGroup>
        {value.id ? (
          settingPassword ? (
            <FormGroup label="Password *" model=".password">
              <Control.text model=".password" className="form-control" />
              <Button
                onClick={onCancelPassword}
                color="link"
                size="sm"
                className="p-0"
              >
                Cancel
              </Button>
              {passwordError}
            </FormGroup>
          ) : (
            <FormGroup label="Password" model=".password">
              <div className="form-control-plaintext">
                <Button
                  onClick={onChangePassword}
                  color="link"
                  size="sm"
                  className="p-0"
                >
                  Change password
                </Button>
              </div>
            </FormGroup>
          )
        ) : (
          <FormGroup label="Password *" model=".password">
            <Control.text model=".password" className="form-control" />
            {passwordError}
          </FormGroup>
        )}
        <FormGroup check label="Is Active?">
          <Control.checkbox model=".is_active" className="form-check-input" />
        </FormGroup>
        <FormGroup check label="Allow access without active subscription?">
          <Control.checkbox
            model=".profile.no_membership_required"
            className="form-check-input"
            changeAction={(m, v) => {
              dispatch(actions.change(m, v));
              if (!v) dispatch(actions.change('local.profile.no_membership_num_users', 1));
            }}
          />
        </FormGroup>
        {value.profile.no_membership_required ? (
          <FormGroup label="Number of Users Allowed *" model=".profile.no_membership_num_users">
            <Control.text model=".profile.no_membership_num_users" className="form-control" />
          </FormGroup>
        ) : null}

        <div className="flex justify-end">
          <SubmitButton>Save</SubmitButton>
        </div>
      </Form>
    </Page>
  );
};

export default Edit;
