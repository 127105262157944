import React from 'react';
import {
  Pagination as ReactstrapPagination,
  PaginationItem,
  PaginationLink
} from 'reactstrap';
import { withPropsOnChange, compose, onlyUpdateForKeys } from 'recompose';
import { range, map, pick } from 'lodash';

export const Pagination = ({
  pages,
  currentPage,
  onPreviousClick,
  onNextClick,
  atEnd,
  atStart,
  ...props
}) => {
  const paginationProps = pick(props, 'className', 'style', 'size', 'tag');

  return (
    <ReactstrapPagination {...paginationProps}>
      <PaginationItem disabled={atStart}>
        <PaginationLink previous href="#" onClick={onPreviousClick} />
      </PaginationItem>
      {pages.map(i => (
        <PaginationItem active={i.active} key={i.page}>
          <PaginationLink href="#" onClick={i.onClick}>
            {i.page}
          </PaginationLink>
        </PaginationItem>
      ))}
      <PaginationItem disabled={atEnd}>
        <PaginationLink next href="#" onClick={onNextClick} />
      </PaginationItem>
    </ReactstrapPagination>
  );
};

const enhance = compose(
  onlyUpdateForKeys(['count', 'offset', 'onOffsetChanged', 'limit']),
  withPropsOnChange(
    ['count', 'offset', 'onOffsetChanged', 'limit', 'noPages'],
    ({ count, limit, offset, onOffsetChanged, noPages }) => {
      const numPages = Math.ceil(count / limit);
      const currentPage = Math.floor(offset / limit) + 1;

      return {
        currentPage,
        atEnd: currentPage === numPages,
        atStart: currentPage === 1,
        onNextClick: e => {
          e.preventDefault();
          onOffsetChanged(offset + limit);
        },
        onPreviousClick: e => {
          e.preventDefault();
          const newOffset = offset - limit;
          if (newOffset < 0) return;
          onOffsetChanged(newOffset);
        },
        pages: map(noPages ? [] : range(1, numPages + 1), i => ({
          page: i,
          onClick: e => {
            e.preventDefault();
            return onOffsetChanged((i - 1) * limit);
          },
          active: i === currentPage
        }))
      };
    }
  )
);

export default enhance(Pagination);
