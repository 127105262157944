import React from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';
import { Trans } from 'services/i18n';

const cardTitleStyle = { fontWeight: 300, display: 'inline-block' };

export const Page = ({
  title,
  children,
  card = true,
  cardBodyClassName,
  translate = true,
  ...props
}) =>
  card ? (
    <Card className="mt-2" {...props}>
      <CardBody className={cardBodyClassName}>
        {title && (
          <CardTitle style={cardTitleStyle}>
            {translate ? <Trans>{title}</Trans> : title}
          </CardTitle>
        )}
        <div className="mt-1">{children}</div>
      </CardBody>
    </Card>
  ) : (
    <div className="mt-2" {...props}>
      <div>
        <CardTitle style={cardTitleStyle}>
          {translate ? <Trans>{title}</Trans> : title}
        </CardTitle>
        <div className="mt-1">{children}</div>
      </div>
    </div>
  );

export default Page;
