import React from 'react';
import { useTable } from 'hooks';
import { Page } from 'components/common';
import { Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';

export const List = ({ match }) => {
  const {
    Pagination,
    ViewingRecords,
    TableContainer,
    TableSearch,
    TableDataFetch,
    data,
    AddNewButton,
    BulkDeleteButton
  } = useTable({
    match,
    resource: 'User',
    url: 'users'
  });

  return (
    <Page title="Users">
      <TableSearch className="fa-min" />
      <div className="flex justify-between mb-3">
        <AddNewButton />
        <BulkDeleteButton />
      </div>
      <TableContainer>
        <Table>
          <thead>
            <tr>
              <th select />
              <th sortBy="email">Email</th>
              <th>Name</th>
              <th>Contact</th>
              <th>Managing User</th>
              <th>Subscription Ends</th>
            </tr>
          </thead>
          <tbody>
            {data.results.map(i => (
              <tr key={i.id}>
                <td select />
                <td>
                  <span className="fa fa-user" />{' '}
                  <Link to={`${match.url}/${i.id}`}>{i.email}</Link>
                </td>
                <td>{i.full_name}</td>
                <td>{i.profile__contact_number}</td>
                <td>
                  {i.profile__managing_user_profile__user__id && (
                    <Link
                      to={`${match.url}/${
                        i.profile__managing_user_profile__user__id
                      }`}
                    >
                      {i.profile__managing_user_profile__user__full_name}
                    </Link>
                  )}
                </td>
                <td>
                  {i.profile__no_membership_required
                    ? 'Never'
                    : i.profile__membership__current_period_end &&
                      moment(i.profile__membership__current_period_end).format(
                        'DD/MM/YYYY'
                      )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </TableContainer>
      <div className="flex justify-between">
        <ViewingRecords />
        <Pagination />
      </div>

      <TableDataFetch />
    </Page>
  );
};

export default List;
