import React from 'react';
import { useTable } from 'hooks';
import { Page, BooleanIcon } from 'components/common';
import { PublishedCount } from 'components';
import { Table } from 'reactstrap';
import { Link } from 'react-router-dom';

export const List = ({
  match,
  resource = 'Course',
  url = 'courses',
  title = 'Courses'
}) => {
  const {
    Pagination,
    ViewingRecords,
    TableContainer,
    TableSearch,
    TableDataFetch,
    data,
    AddNewButton,
    BulkDeleteButton
  } = useTable({
    match,
    resource,
    url
  });

  return (
    <Page title={title}>
      <TableSearch className="fa-min" />
      <div className="flex justify-between mb-3">
        <AddNewButton />
        <BulkDeleteButton />
      </div>
      <TableContainer>
        <Table>
          <thead>
            <tr>
              <th select />
              <th sortBy="title">Title</th>
              <th sortBy="order" className="text-right">
                Order
              </th>
              <th>Active Modules</th>
              <th sortBy="published" className="text-center">
                Published?
              </th>
            </tr>
          </thead>
          <tbody>
            {data.results.map(i => (
              <tr key={i.id}>
                <td select />
                <td>
                  <Link to={`${match.url}/${i.id}`}>{i.title}</Link>
                </td>
                <td className="text-right">{i.order}</td>
                <td>
                  <PublishedCount
                    total={i.num_modules}
                    published={i.published_modules}
                  />
                </td>
                <td>
                  <BooleanIcon test={i.published} />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </TableContainer>
      <div className="flex justify-between">
        <ViewingRecords />
        <Pagination />
      </div>

      <TableDataFetch />
    </Page>
  );
};

export default List;
