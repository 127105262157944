import React, { useContext } from 'react';
import { useTable } from 'hooks';
import { Page } from 'components/common';
import { Table } from 'reactstrap';
import { toDateTime } from 'services/formatters';
import { MembershipContext } from 'contexts';
import { Link } from 'react-router-dom';

const Subscriptions = () => {
  const { membership, profile } = useContext(MembershipContext);
  const {
    data,
    BulkDeleteButton,
    TableContainer,
    EditDeleteButtons,
    ViewingRecords,
    Pagination,
    TableDataFetch
  } = useTable({
    url: 'managing_user/users',
    limit: 100
  });

  const quantity = profile.no_membership_required ? profile.no_membership_num_users : membership.quantity;

  return (
    <Page title={`Users (${data.count}/${quantity})`}>
      <div className="flex justify-between mb-3">
        <BulkDeleteButton className="hidden" />
      </div>
      <TableContainer>
        <Table>
          <thead>
            <tr>
              <th>Email</th>
              <th>Name</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {data.results.map(i => (
              <tr key={i.id}>
                <td>{i.user__email}</td>
                <td>{i.user__full_name}</td>
                <td>
                  <EditDeleteButtons id={i.id} deleteText="Revoke Access" />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </TableContainer>
      <div className="flex justify-between">
        <ViewingRecords />
        <Pagination />
      </div>
      <TableDataFetch />
    </Page>
  );
};

const Invitations = ({ match }) => {
  const {
    data,
    BulkDeleteButton,
    EditDeleteButtons,
    TableContainer,
    ViewingRecords,
    Pagination,
    TableDataFetch
  } = useTable({
    url: 'managing_user/invites',
    limit: 100,
    match
  });

  return (
    <Page title="Invitations">
      <div className="flex justify-end">
        <Link className="btn btn-success mb-2" to={`${match.url}/invite`}>
          Send Invites
        </Link>
      </div>
      <BulkDeleteButton className="hidden" />
      <TableContainer>
        <Table>
          <thead>
            <tr>
              <th>Email</th>
              <th>Sent</th>
              <th>Accepted</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {data.results.map(i => (
              <tr key={i.id}>
                <td>{i.recipient}</td>
                <td>{toDateTime(i.sent)}</td>
                <td>{i.accepted ? toDateTime(i.accepted) : null}</td>
                <td>
                  <EditDeleteButtons id={i.id} />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </TableContainer>
      <div className="flex justify-between">
        <ViewingRecords />
        <Pagination />
      </div>
      <TableDataFetch />
    </Page>
  );
};

export const ManageSubscriptions = ({ client, match }) => {
  return (
    <div>
      <Subscriptions client={client} />
      <Invitations match={match} />
    </div>
  );
};

export default ManageSubscriptions;
