import React, { useCallback, useState, useRef } from 'react';
import {
  Modal as BaseModal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';

export const useModal = () => {
  const [isOpen, setIsOpen] = useState();
  const toggle = useCallback(() => setIsOpen(!isOpen), [isOpen]);
  const ref = useRef();
  ref.current = { isOpen, setIsOpen, toggle };

  const Modal = useCallback(props => {
    const { isOpen, toggle } = ref.current;
    return <BaseModal isOpen={isOpen} toggle={toggle} {...props} />;
  }, []);

  return {
    isOpen,
    setIsOpen,
    toggle,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
  };
};

export default useModal;
