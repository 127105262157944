import '@babel/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { get, throttle, startsWith } from 'lodash';
import { NotificationManager } from 'react-notifications';
import App from './App';
import './scss/bootstrap_custom.scss';
import 'font-awesome/scss/font-awesome.scss';
import './scss/index.scss';
import 'react-notifications/dist/react-notifications.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'video-react/dist/video-react.css';
import 'react-select/dist/react-select.min.css';
import history from './config/history';
import Promise from 'bluebird';
import 'moment';
import 'moment-duration-format';
import { UserContextProvider } from 'contexts/UserContext';
import { MembershipProvider } from 'contexts/MembershipContext';
import {SWRConfig} from "swr";

window.Promise = Promise;

const Container = () => (
  <SWRConfig value={{
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    shouldRetryOnError: false,
  }}>
    <Router history={history}>
      <UserContextProvider>
        <MembershipProvider>
          <App />
        </MembershipProvider>
      </UserContextProvider>
    </Router>
  </SWRConfig>
);

const serverErrorNotification = throttle(
  () => NotificationManager.error('An unexpected error occurred'),
  2000
);
const clientErrorNotification = throttle(
  () =>
    NotificationManager.error(
      'An error occurred, please check the provided input and try again'
    ),
  2000
);
const connectionNotification = throttle(
  () =>
    NotificationManager.error(
      'You may be offline, please check your internet connection and try again'
    ),
  2000
);

window.addEventListener('unhandledrejection', e => {
  const { reason } = e.detail;

  if (startsWith(get(reason, 'config.baseURL'), '/api')) {
    if (reason.handled) {
      e.preventDefault();
      return;
    }

    const status = get(reason, 'response.status', null);
    const code = get(reason, 'response.data.code');

    if ((status >= 500 && status < 600) || status === 404) {
      serverErrorNotification();
    } else if (status >= 400 && status < 500) {
      if (code !== 'AccountSuspended') {
        clientErrorNotification();
      }
    } else if (status === null) {
      connectionNotification();
    } else {
      return;
    }
    e.preventDefault();
  }
});

ReactDOM.render(<Container />, document.getElementById('root'));
