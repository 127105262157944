import React from 'react';
import { compose, withHandlers, withPropsOnChange, withState } from 'recompose';
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
import { keys } from 'lodash';
import { ApiClient } from 'services';
import { PendingButton } from 'components/common';
import { NotificationManager } from 'react-notifications';
import { Trans, translate } from 'services/i18n';

const DeleteModal = ({ isOpen, toggle, numSelected, onConfirm, pending }) => (
  <Modal isOpen={isOpen} toggle={toggle}>
    <ModalBody>
      <Trans>
        Are you sure you want to delete the {numSelected} selected rows?
      </Trans>
    </ModalBody>
    <ModalFooter>
      <PendingButton color="primary" pending={pending} onClick={onConfirm}>
        Confirm
      </PendingButton>{' '}
      <Button color="secondary" onClick={toggle}>
        Cancel
      </Button>
    </ModalFooter>
  </Modal>
);

const enhance = compose(
  withPropsOnChange(['client'], ({ client }) => ({
    client: client || ApiClient()
  })),
  withState('pending', 'setPending', false),
  translate(),
  withPropsOnChange(['selected'], ({ selected: tmpSelected }) => {
    const selected = keys(tmpSelected);

    return {
      numSelected: selected.length,
      selected
    };
  }),
  withHandlers({
    onConfirm: ({
      onConfirm,
      url,
      selected,
      onDeleted,
      isOpen,
      toggle,
      setPending,
      client,
      t
    }) => () => {
      setPending(true);
      let promise = null;
      const failed = [];
      if (onConfirm) {
        promise = onConfirm();
      } else {
        promise = Promise.mapSeries(
          selected,
          i => {
            return client.delete(`${url}/${i}`).catch(err => {
              failed.push(i);
            });
          },
          { concurrency: 1 }
        ).tap(() => {
          if (failed.length) {
            NotificationManager.error(
              `${failed.length} row(s) could not be deleted`
            );
          }
        });
      }

      promise
        .tap(() => {
          if (isOpen) toggle();
          onDeleted(failed.length ? failed : null);
        })
        .finally(() => setPending(false));
    }
  })
);

export default enhance(DeleteModal);
