import React, { useEffect, useContext } from 'react';
import { useForm, usePageHelpers } from 'hooks';
import { Info, Warning } from 'components/common/Message';
import numeral from 'numeral';
import { get } from 'lodash';
import { MembershipContext } from 'contexts';

export const UpdateMembership = ({ client, history }) => {
  const { PageTitle, PageCard } = usePageHelpers();
  const { reload } = useContext(MembershipContext);
  const {
    Form,
    setValue,
    loaded,
    FormGroup,
    SubmitButton,
    value,
    Control,
    validators,
    dispatch,
    Errors,
    actions
  } = useForm();

  useEffect(() => {
    (async () => {
      const { quantity } = await client.get('membership').get('data');
      setValue({
        quantity: `${quantity}`,
        loading: false,
        initialQuantity: `${quantity}`,
        costDifference: { quantity: 0 }
      });
    })();
  }, []);

  function onQuantityChange(m, v) {
    dispatch(actions.change(m, v));
    dispatch(actions.change('local.loading', true));

    if (!validators.isInteger(v) || parseInt(v) === 0) {
      return;
    }

    client
      .post('membership/calculate_cost_difference', { quantity: v })
      .get('data')
      .then(v => {
        dispatch(actions.change('local.costDifference', v));
      })
      .finally(() => dispatch(actions.change('local.loading', false)));
  }

  async function onSubmit(x) {
    dispatch(actions.resetErrors('server'));

    try {
      await client.post('membership/update_quantity', x).get('data');
      reload();
      history.pushUp();
    } catch (ex) {
      const code = get(ex, 'response.data.code');

      if (code) {
        dispatch(
          actions.setErrors('server', {
            [code]: ex.response.data.detail
          })
        );
      }
    } finally {
      dispatch(actions.setPending('local', false));
    }
  }

  if (!loaded) return null;

  const {
    costDifference: { diff, cost, currency, new_amount }
  } = value;

  return (
    <div>
      <PageTitle>Update Subscription</PageTitle>
      <PageCard className="p-3">
        <Form
          onSubmit={onSubmit}
          validators={{
            quantity: v => validators.isInteger(v) && v >= 1,
            loading: v => !v,
            '': v => v.quantity !== v.initialQuantity
          }}
        >
          <FormGroup label="Number of Users" model=".quantity">
            <Control.text
              debounce={500}
              changeAction={onQuantityChange}
              model=".quantity"
              className="form-control"
            />
          </FormGroup>
          {diff >= 0 && (
            <Info>
              {!!cost && (
                <div>
                  You will be billed an additional{' '}
                  <strong>
                    {numeral(cost)
                      .divide(100)
                      .format('0.00')}
                    {currency}
                  </strong>{' '}
                  for the increase of <strong>{diff}</strong> users this billing
                  period.
                </div>
              )}
              <div>
                Your new billing period total will be{' '}
                <strong>
                  {numeral(new_amount)
                    .divide(100)
                    .format('0.00')}
                  {currency}
                </strong>
              </div>
            </Info>
          )}
          {diff < 0 && (
            <Info>
              <div>
                Your new billing period total will be{' '}
                <strong>
                  {numeral(new_amount)
                    .divide(100)
                    .format('0.00')}
                  {currency}
                </strong>
              </div>
            </Info>
          )}
          <Errors
            component={Warning}
            model="server"
            messages={{
              MembershipReductionRequired: (v, e) => (
                <span>
                  You must unlink <strong>{e.reduction_required}</strong> before
                  reducing your subscription to <strong>{e.quantity}</strong>{' '}
                  users.
                </span>
              )
            }}
          />
          <div className="flex justify-end">
            <SubmitButton model="local" disableInvalid>
              Update
            </SubmitButton>
          </div>
        </Form>
      </PageCard>
    </div>
  );
};

export default UpdateMembership;
