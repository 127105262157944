import React, { useMemo, useCallback, useState, useRef } from 'react';
import { Provider as ReactReduxProvider, ReactReduxContext } from 'react-redux';
import { createStore } from 'redux';
import { FormGroup, SubmitButton } from 'components/common';
import { validators } from 'services';
import {
  combineForms,
  actions,
  Form,
  getField,
  Control,
  Fieldset,
  Errors
} from 'react-redux-form';
import { get } from 'lodash';
import shortid from 'shortid';

export const FormContext = ReactReduxContext;

const createComponents = ref => () => {
  const Provider = props => {
    const { store } = ref.current;
    return <ReactReduxProvider store={store} {...props} />;
  };

  return {
    Provider,
    Form: ({ onChange: baseOnChange, ...props }) => {
      const { forceUpdate, key, model } = ref.current;

      const onChange = useCallback(
        value => {
          forceUpdate();
          if (baseOnChange) baseOnChange(value);
        },
        [baseOnChange]
      );

      return (
        <Provider>
          <Form key={key} onChange={onChange} model={model} {...props} />
        </Provider>
      );
    }
  };
};

export const useForm = ({ model = 'local', initialState = {} } = {}) => {
  const [loaded, setLoaded] = useState(false);
  const [, _forceUpdate] = useState();
  const valueRef = useRef();
  const fieldRef = useRef();
  const [store, dispatch, key] = useMemo(
    () => {
      const store = createStore(
        combineForms({
          [model]: initialState
        })
      );

      const dispatch = action => {
        return typeof action === 'function'
          ? action(store.dispatch, store.getState)
          : store.dispatch(action);
      };

      return [store, dispatch, shortid()];
    },
    [model]
  );

  const state = store.getState();
  fieldRef.current = getField(state, model);
  valueRef.current = get(state, model);

  const setValue = value => {
    dispatch(actions.change(model, value, { silent: true }));
    dispatch(actions.setInitial(model));
    setLoaded(true);
  };

  const save = async (client, url, item, resetPending = true) => {
    try {
      return item.id
        ? await client.put(`${url}/${item.id}`, item)
        : await client.post(url, item);
    } finally {
      if (resetPending) {
        dispatch(actions.setPending('local', false));
      }
    }
  };

  const load = async (client, url, id, defaultValue) => {
    return id === 'new' ? defaultValue : client.get(`${url}/${id}`).get('data');
  };

  const forceUpdate = () => _forceUpdate(v => !v);

  const getTitle = (id, title) => {
    return id === 'new' ? `Create ${title}` : `Edit ${title}`;
  };

  const componentRef = useRef();
  componentRef.current = { forceUpdate, key, store, model };

  const components = useMemo(createComponents(componentRef), []);

  return {
    model,
    setValue,
    loaded,
    field: fieldRef.current,
    value: valueRef.current,
    store,
    dispatch,
    save,
    load,
    valueRef,
    fieldRef,
    Control,
    Errors,
    FormGroup,
    actions,
    validators,
    SubmitButton,
    getTitle,
    forceUpdate,
    Fieldset,
    ...components,
    FormContext
  };
};

export default useForm;
