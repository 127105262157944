import React, { useState, useEffect, useContext } from 'react';
import { useForm } from 'hooks';
import { FormGroup, required, SubmitButton, Page } from 'components/common';
import { Fieldset } from 'react-redux-form';
import { Alert } from 'reactstrap';
import { get } from 'lodash';
import Select from 'react-select';
import { vatFormats } from '../Account/VatFormats';
import numeral from 'numeral';
import moment from 'moment';
import { isInteger } from 'services/validators';
import { getData } from 'country-list';
import { NotificationManager } from 'react-notifications';
import { MembershipContext } from 'contexts';
import {
  injectStripe,
  CardElement,
  StripeProvider,
  Elements
} from 'react-stripe-elements';

const countryOptions = getData().map(({ name, code }) => ({
  key: code,
  label: name,
  value: code
}));

const subscriptionTypes = [
  { label: 'Single User', value: 'SINGLE' },
  { label: 'Multiple Users', value: 'MULTIPLE' }
];

const Payment = injectStripe(({ stripe, ...props }) => {
  async function onChange(card) {
    let result = null;

    if (card.complete && !card.error) {
      const response = await stripe.createSource({ type: 'card' });
      result = get(response, 'source.id');
    }

    props.onChange(result);
  }
  return <CardElement onChange={onChange} />;
});

const PlanInformation = ({
  plan: { currency, amount, trial_period_days, interval },
  numUsers,
  className,
  style
}) => {
  const total = numeral((amount * numUsers) / 100).format('0.00');
  return (
    <Alert color="info" className={className} style={style}>
      {!!trial_period_days && (
        <div>
          This subscription includes a <strong>{trial_period_days}</strong> day
          free trial which you can cancel at any time
          <br />
          Your first billing date will be the{' '}
          <strong>
            {moment()
              .add(trial_period_days, 'days')
              .format('Do of MMMM')}
          </strong>
        </div>
      )}
      You will be billed{' '}
      <strong>
        {total}
        {currency}
      </strong>{' '}
      per <strong>{interval}</strong> for <strong>{numUsers}</strong>{' '}
      {numUsers > 1 ? 'users' : 'user'}
    </Alert>
  );
};

export const SetupSubscription = ({ client, history }) => {
  const [plan, setPlan] = useState();
  const { reload } = useContext(MembershipContext);

  const {
    Form,
    Control,
    dispatch,
    actions,
    value,
    setValue,
    loaded
  } = useForm();

  useEffect(() => {
    (async () => {
      const [profile, plan] = await Promise.all([
        client.get('user_profile').get('data'),
        client.get('membership/plan').get('data')
      ]);

      setPlan(plan);

      setValue({
        first_name: profile.user__first_name,
        last_name: profile.user__last_name,
        subscription_type: 'SINGLE',
        num_users: 1,
        payment_source: null,
        membership: {
          address_line_1: '',
          address_line_2: '',
          city: '',
          country: '',
          vat_number: '',
          postcode: ''
        }
      });
    })();
  }, []);

  if (!loaded) return null;

  const showVat = vatFormats[value.membership.country];

  async function onSubmit(x) {
    dispatch(actions.resetErrors('server'));

    try {
      await client.post('/membership/setup', x);
      reload();
      NotificationManager.success('Subscription setup successfully');
      history.replaceUp();
    } catch (ex) {
      const code = get(ex, 'response.data.code');

      if (code === 'invalid') {
        const data = ex.response.data;
        dispatch(actions.setErrors('server.email', data.email));
        dispatch(actions.setErrors('server.password', data.password));
      } else {
        throw ex;
      }
    } finally {
      dispatch(actions.setPending('local', false));
    }
  }

  return (
    <Page title="Setup Subscription" className="max-w-lg mx-auto">
      <Form
        validators={{
          subscription_type: required,
          first_name: required,
          last_name: required,
          num_users: isInteger,
          payment_source: required,
          'membership.address_line_1': required,
          'membership.city': required,
          'membership.postcode': required,
          'membership.country': required,
          'membership.vat_number': v => (showVat ? required(v) : true)
        }}
        onSubmit={onSubmit}
      >
        <FormGroup label="First Name" model=".first_name">
          <Control.text className="form-control" model=".first_name" />
        </FormGroup>
        <FormGroup label="Last Name" model=".last_name">
          <Control.text className="form-control" model=".last_name" />
        </FormGroup>
        <FormGroup label="Subscription Type" model=".subscription_type">
          <Control.text
            onChange={e => {
              dispatch(
                actions.change('local.num_users', e === 'SINGLE' ? 1 : 2)
              );
            }}
            clearable={false}
            component={Select}
            model=".subscription_type"
            searchable={false}
            options={subscriptionTypes}
            simpleValue
          />
        </FormGroup>
        {value.subscription_type === 'MULTIPLE' && (
          <FormGroup label="Number of Users" model=".num_users">
            <Control.text model=".num_users" className="form-control" />
          </FormGroup>
        )}
        <Fieldset model=".membership">
          <FormGroup label="Billing Address" model=".address_line_1">
            <Control.text
              placeholder="Address Line 1"
              model=".address_line_1"
              className="form-control"
            />
          </FormGroup>
          <FormGroup label="" model=".address_line_2">
            <Control.text
              placeholder="Address Line 2"
              model=".address_line_2"
              className="form-control"
            />
          </FormGroup>
          <FormGroup label="" model=".city">
            <Control.text
              placeholder="City"
              model=".city"
              className="form-control"
            />
          </FormGroup>
          <FormGroup label="" model=".postcode">
            <Control.text
              placeholder="Postcode"
              model=".postcode"
              className="form-control"
            />
          </FormGroup>
          <FormGroup label="" model=".country">
            <Control.text
              component={Select}
              options={countryOptions}
              simpleValue
              model=".country"
              clearable={false}
              placeholder="Country"
              onChange={v => {
                if (!vatFormats[v]) {
                  dispatch(actions.change('local.vat_number', ''));
                }
              }}
            />
          </FormGroup>
          {showVat && (
            <FormGroup label="VAT Number *" model=".vat_number">
              <Control.text model=".vat_number" className="form-control" />
            </FormGroup>
          )}
        </Fieldset>
        <FormGroup label="Payment Details">
          <StripeProvider apiKey={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}>
            <Elements>
              <Control.custom
                mapProps={({ onChange }) => ({ onChange })}
                model=".payment_source"
                component={Payment}
              />
            </Elements>
          </StripeProvider>
        </FormGroup>

        {plan && (
          <PlanInformation
            plan={plan}
            className="mt-2"
            numUsers={value.num_users}
          />
        )}

        <div className="flex justify-end">
          <SubmitButton>Confirm</SubmitButton>
        </div>
      </Form>
    </Page>
  );
};

export default SetupSubscription;
