import moment from 'moment';
import numeral from 'numeral';

export const toTime = (v, format = 'h [hours] m [minutes]') =>
  moment.duration(v, 'minutes').format(format);

export const toDateTime = (v, format = 'YYYY-MM-DD HH:mm') =>
  moment(v).format(format);

export const toCurrency = (v, format = '0.00') => numeral(v).format(format);
